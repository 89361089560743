import React, { useState, useEffect } from 'react'

import LocationSearchInput from '../components/PlacesAutocomplete'
import Search from './Search'
import { LocationOptions } from '../constants/LocationOptions'
import { Location } from '../parish-map/Types'

import './landing.scss'
import Radio from '../components/Radio'

interface Props {
  // searchResultClicked: (parishId: number, personId?: number) => void
  searchKeywords: (text: string) => string
}

export default function Landing(props: Props) {
  const useAddressValue = 'Near an address, city, or town'
  const useLocationValue = 'Near your current location'
  const usePersonValue = 'Search by name'

  const [address, setAddress] = useState('')
  const [lat, setLat] = useState<number | undefined>(undefined)
  const [lng, setLng] = useState<number | undefined>(undefined)
  const [useAddress, setUseAddress] = useState(useLocationValue)

  useEffect(() => {
  }, [useAddress])

  const onAddressUpdate = (s: string) => {
    setAddress(s)
  }

  const onAddressSelect = (location: Location) => {
    setAddress(location.address)
    setLat(location.lat)
    setLng(location.lng)
  }

  const onRadio = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUseAddress(event.target.value)
  }

  const onClick = () => {
    if (useAddress === useAddressValue && address) {
      let addressValue = address ? `address=${encodeURIComponent(address)}` : ''
      let latValue = lat ? `&lat=${encodeURIComponent(lat)}` : ''
      let lngValue = lng ? `&lng=${encodeURIComponent(lng)}` : ''
      window.location.href = `/parishes?${addressValue}${latValue}${lngValue}`
    }
    //if there is no keyword in the searchbox, just take the visitor to the parishes page which will display their current location on the map
    else {
      window.location.href = `/parishes`
    }
  }

  const searchResultClicked = async (parishId: number, personId?: number) => {
    let url = `/parishes?parishid=${parishId}`
    if (personId !== undefined) {
      url += `&personid=${personId}`
    }
    window.location.href = url
  }

  const setInput = () => {
    if (useAddress === usePersonValue/*'Search by name'*/) {
      return(
        <Search searchResultClicked={searchResultClicked} searchKeywords={props.searchKeywords}>

        </Search>
      )
    } else {
      return(
        <LocationSearchInput
        onClick={() => { setUseAddress(useAddressValue) }}
        onUpdate={onAddressUpdate}
        onSelect={onAddressSelect}
        role={LocationOptions.byAddress}
        address={address}
      />
      )
    }
  }

  return (
    //the below commented out code will be the beginning code for the new search function is CAEDM approves it
    <div className="">
      {/* <p className="text-white margin-left-15px"><em>Jump to a Parish or Find a Person...</em></p> */}
      <div className="d-flex flex-column col-12 flex-wrap landing-location-src"></div>
      <div className="col-12 col-lg-12 px-0">
        <Search searchResultClicked={searchResultClicked} searchKeywords={props.searchKeywords}>
          <input
            className="button__default-primary offset-2 col-8 offset-lg-0 col-lg-6 offset-lg-6"
            type="button"
            value="Search"
            onClick={onClick}
          />
        </Search>
      </div>
    </div>
  )
}

