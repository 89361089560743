import {
  WP_REST_URL_PARISHES,
  WP_REST_URL_PARISH,
  WP_REST_URL_MULTI_PARISH,
  WP_REST_URL_PARISH_FILTERABLES,
  WP_REST_URL_PARISHES_PAGE,
  WP_REST_URL_PARISHES_MASS,
} from '../constants/constants';

export default class Parish {
  static getAll = async () => {
    return (await fetch(WP_REST_URL_PARISHES, { method: 'GET' })).json();
  };

  static getParishesPage = async () => {
    return (await fetch(WP_REST_URL_PARISHES_PAGE, { method: 'GET' })).json();
  };

  static getParishDetails = async (idArray: number[]) => {
    return (await fetch(WP_REST_URL_MULTI_PARISH, {
      method: 'POST',
      body: JSON.stringify({ ids: idArray }),
    })).json();
  };

  static search = async (searchTerm: string) => {
    return (await fetch(`${WP_REST_URL_PARISH}/search`, {
      method: 'POST',
      body: searchTerm,
    })).json();
  };

  static filterables = async () => {
    return (await fetch(`${WP_REST_URL_PARISH_FILTERABLES}`, {
      method: 'GET',
    })).json();
  };

  static getAllMass = async () => {
    return (await fetch(WP_REST_URL_PARISHES_MASS, { method: 'GET' })).json();
  };
}
