import {
  WP_REST_URL_EVENTS,
  WP_REST_URL_EVENT_TYPES,
} from '../constants/constants';

export default class WPApi {
  static getEventsByDate = async (date: string) => {
    return (await fetch(`${WP_REST_URL_EVENTS}?start_time=${date}`, {
      method: 'GET',
    })).json();
  };

  static getAllEvents = async () => {
    return (await fetch(WP_REST_URL_EVENTS, { method: 'GET' })).json();
  };

  static getAllEventFilters = async () => {
    return (await fetch(WP_REST_URL_EVENT_TYPES, {
      method: 'GET',
    })).json();
  };
}
