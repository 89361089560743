import React from 'react'
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from 'react-places-autocomplete'
import { Location } from '../parish-map/Types'
import {
  LocationOptions,
  EdmontonCoordinates,
} from '../constants/LocationOptions'

interface ILocationSearchInputState {
  address: string
}

interface ILocationSearchInputProps {
  onSelect: (address: Location) => void
  onClick: (e: any) => void
  role: string
  address: string
  onUpdate: (e: any) => void
}

const searchOptions = {
  location: new google.maps.LatLng(
    EdmontonCoordinates.lat,
    EdmontonCoordinates.lng
  ),
  radius: 50000,
  componentRestrictions: { country: 'CA' },
  types: ['address']
}
class LocationSearchInput extends React.Component<
  ILocationSearchInputProps,
  ILocationSearchInputState
  > {
  constructor(props: ILocationSearchInputProps) {
    super(props)
  }

  handleChange = (address: string) => {
    this.props.onUpdate(address)
  }

  handleSelect = async (address: string) => {
    const geoAddress = await geocodeByAddress(address)
    const latLng = await getLatLng(geoAddress[0])

    const location = { address: geoAddress[0].formatted_address, ...latLng }
    this.props.onSelect(location)
  }

  render() {
    return (
      <PlacesAutocomplete
        value={this.props.address}
        onChange={this.handleChange}
        onSelect={this.handleSelect}
        searchOptions={searchOptions}
      >
        {({ getInputProps, suggestions, getSuggestionItemProps, loading }) => (
          <div className='mb-3'>
            <input
              {...getInputProps({
                placeholder: 'Search Places ...',
                className: 'location-search-input',
              })}
              role={LocationOptions.byAddress}
              onClick={this.props.onClick}
            />
            <div className='autocomplete-dropdown-container'>
              {loading && <div>Loading...</div>}
              {suggestions.map(suggestion => {
                const className = suggestion.active
                  ? 'suggestion-item--active'
                  : 'suggestion-item'
                return (
                  <div
                    {...getSuggestionItemProps(suggestion, {
                      className,
                    })}
                  >
                    <span>{suggestion.description}</span>
                  </div>
                )
              })}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    )
  }
}

export default LocationSearchInput
