import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'

interface Props {
  show?: boolean
  hideSearch: () => void,
  searchTerm: string,
  setSearchTerm: (term: string) => void,
}

const Search = (props: Props) => {

  const { searchTerm, setSearchTerm, show, hideSearch } = props

  return <div className={`${props.show ? 'show-full' : ''}`}>
    {!show && <div className="px-5">Search</div>}
    {
      show && (
        <div className="d-flex align-items-center mr-5 my-5">
          <input
            className={(show ? "flex-1 mx-5" : "flex-0 mx-5")}
            type="text"
            placeholder="Search by keyword..."
            value={searchTerm}
            onChange={event => {
              setSearchTerm(event.target.value)
            }}
          />
          <div onClick={searchTerm ? () => setSearchTerm('') : () => hideSearch()} className="close">
            <FontAwesomeIcon size="2x" icon={faTimes} />
          </div>
        </div>
      )
    }

  </div>
}

export default Search
