import React, { useState, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { useAsync } from 'react-async-hook'
import useConstant from 'use-constant'

import ParishAPI from '../api/Parish'
import { SearchResult } from './Types'

interface Props {
  children: React.ReactNode
  searchResultClicked: (/*parishId: number, personId?: number*/searchResult: SearchResult) => void,
  searchKeywords: (text: string) => string
}

const Search = (props: Props) => {
  const [showResults, setShowResults] = useState(false)
  const [searchTerm, setSearchTerm] = useState<string>('')
  const [searchResults, setSearchResults] = useState<SearchResult[]>([])
  const [searching, setSearching] = useState<boolean>(false)

  const search = async (text: string) => {
    try {
      if (text.length === 0) {
        setShowResults(false)
        return
      }

      let updatedSearchTerm = props.searchKeywords(text);

      setSearching(true)
      let searchResults = await ParishAPI.search(updatedSearchTerm)
      setSearchResults(searchResults)
      setShowResults(true)
      setSearching(false)
    } catch (error) {
      console.warn(error)
    }
  }

  const onClick = (searchResult: SearchResult) => {
    setShowResults(false)
    setSearchResults([])
    props.searchResultClicked(searchResult)
  }

  // check back to see if awesome-debounce, use-constant and useAsync have built-in
  // react equivalents later on.
  const searchAPIDebounced = useConstant(() =>
    AwesomeDebouncePromise(search, 1000)
  )

  useAsync(
    async (text: string) => {
      return searchAPIDebounced(text)
    },
    [searchTerm]
  )

  const clearSearch = () => {
    setSearchTerm('')
    setSearchResults([])
    setShowResults(false)
  }

  // render results
  let searchResultsList: JSX.Element[] = []

  if (showResults && searchResults.length === 0) {
    searchResultsList.push(
      <div key={0} className="searchResult">
        No Results
      </div>
    )
  }


  // console.log(searchResultsList);
  searchResults.map((searchResult, index) => {
    return searchResultsList.push(
      <div
        className="searchResult"
        key={index}
        onClick={() => onClick(searchResult)}
      >
        {searchResult.person && (
          <div className="person">{searchResult.person.name}</div>
        )}
        <div className="parish">{searchResult.parish.name}</div>
      </div>
    )
  })

  return (
    <div className="searchContainer">
      <div className="search">
        {searching && <FontAwesomeIcon icon={faSpinner} spin />}
        {!searching && <FontAwesomeIcon icon={faSearch} className="color-primary" />}
        <div className="input">
          <input
            onFocus={() => {
              setShowResults(false)
            }}
            type="text"
            placeholder="Find a parish or person..."
            value={searchTerm}
            onChange={event => {
              setSearchTerm(event.target.value)
            }}
          />
          <button className="clear" onClick={clearSearch}>
            <FontAwesomeIcon icon={faTimes} className="color-primary" />
          </button>
        </div>
        {props.children}
      </div>
      <div className="eeeeeee search-results-container">{searchResultsList}</div>
    </div>
  )
}

export default Search
