import React, { useState, useEffect, useLayoutEffect, useRef } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faEnvelope,
  faFax,
  faGlobeAmericas,
  faMapMarkerAlt,
  faPhone,
  faSpinner,
  faTimes,
  faBuilding,
  faChild,
  faUsers,
  faVenusMars,
  faBook,
  faInfoCircle,
  faMapSigns,
} from '@fortawesome/free-solid-svg-icons'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.scss'

import ParishAPI from '../api/Parish'
import { ParishDetails, Person, School, Time, Location } from './Types'
import Overlay from './Overlay'
import MassTime from './MassTime'

var classNames = require('classnames')

interface Props {
  searchedPerson: number
  foundSearchedPerson: boolean
  selected: number
  hasMenuAnimated: boolean
  showMenu: boolean
  closeMenu: () => void
  locationForDirections: Location
}

const generateDirections = (
  userLocation: Location,
  parishLocation: Location
): string => {
  const baseUrl = 'https://www.google.com/maps/dir/?api=1'
  const origin = `&origin=${userLocation.lat}, ${userLocation.lng}`
  const destination = `&destination=${parishLocation.lat}, ${parishLocation.lng}`

  return baseUrl + origin + destination
}

const Menu = (props: Props) => {
  const [requesting, setRequesting] = useState(false)
  const [tabIndex, setTabIndex] = useState(0)
  const [mailTabIndex, setMailTabIndex] = useState(0)
  const [details, setDetails] = useState<ParishDetails>({
    id: -1,
    name: '',
    mass: [],
    schools: [],
    clergy: [],
    staff: [],
    notes: '',
    landAcknowledgement: '',
    email: '',
    location: { lat: 181, lng: 181, address: '' },
    mailAddress: '',
    officeAddress: '',
    phoneNumber: '',
    faxNumber: '',
    url: '',
    accessible: false,
    extraPrograms: [],
    image: ''
  })

  const parishDetailsRef = useRef<HTMLDivElement>(null);//useRef(null);
  const parishContentRef = useRef<HTMLDivElement>(null);
  const [menuVerticalScroll, setMenuVerticalScroll] = useState(false);

  const getMenuScroll = () => {
    if(parishDetailsRef.current && parishDetailsRef.current.clientHeight &&
      parishContentRef.current && parishContentRef.current.clientHeight){

      let parishDetailsRefHeight = parishDetailsRef.current.clientHeight;
      let parishContentRefHeight = parishContentRef.current.clientHeight;

      if(parishContentRefHeight > parishDetailsRefHeight){
        setMenuVerticalScroll(true);
      }
      else{
        setMenuVerticalScroll(false);
      }
    }
  };

  const getParish = async () => {
    if (!requesting) {
      try {
        if (props.selected !== -1 && props.selected !== details.id) {

          // console.log(`request BEFORE = ${requesting}`);

          setRequesting(true);

          // console.log(`request inbetween = ${requesting}`);

          let parish = await ParishAPI.getParishDetails([props.selected]);
          setDetails(parish[props.selected]);
          setRequesting(false);

          getMenuScroll();

          // console.log(`request after = ${requesting}`);
        }
      } catch (error) {
        setRequesting(false)
        console.warn(error)
      }
    }
  }

  useEffect(() => {
    (async () => { await getParish() })()
    }, [props.selected])

  let masses: any = {}
  details.mass.forEach((mass: Time, index: number) => {
    if (mass.type) {
      if (!masses[mass.type]) {
        masses[mass.type] = []
      }
      masses[mass.type].push(mass)
    }
  })

  let massTimes: JSX.Element[] = []
  for (let type in masses) {
    massTimes.push(<h3 key={type}>{type}</h3>)
    masses[type].forEach((mass: Time, index: number) => {
      massTimes.push(<MassTime key={`${type}-${index}`} time={mass} />)
    })
  }

  let schools: JSX.Element[] = []
  details.schools.forEach((school: School, index: number) => {
    schools.push(
      <div key={index}>
        <div>{school.name}</div>
        <div>{school.grades}</div>
      </div>
    )
  })

  let searchedPersonInfo: JSX.Element[] = [];

  if(props.foundSearchedPerson === true && searchedPersonInfo.length === 0 && props.showMenu){
    props.foundSearchedPerson = false;
  }

  let clergy: JSX.Element[] = []
  details.clergy.forEach((clergyPerson: Person, index: number) => {
    let personImage: JSX.Element[] = [];

    if(clergyPerson.image != 'false'){
      personImage.push(
        <div className='person-image' style={{backgroundImage: `url(${clergyPerson.image})`}}></div>
      );
    }
    
    if(props.foundSearchedPerson === false && props.searchedPerson == clergyPerson.ID && props.showMenu){
      searchedPersonInfo.push(
        <div className="searched-person">
          <h4 className="obj-title">Searched person:</h4>
          {personImage}
          <div className="person-name">{clergyPerson.name}</div>
          <div><span>Phone:</span> {clergyPerson.phoneNumber}</div>
          <div><span>Email:</span> <a href={'mailto:' + clergyPerson.email}>{clergyPerson.email}</a></div>
        </div>
      );

      props.foundSearchedPerson = true;
    }

    clergy.push(
      <div key={index} className="person">
        <div>{personImage}</div>
        <div>
          <div className='name'>{clergyPerson.name}</div>
          <div className='position'>{clergyPerson.position}</div>
          <div>{clergyPerson.phoneNumber}</div>
          <div><a href={'mailto:' + clergyPerson.email}>{clergyPerson.email}</a></div>
        </div>
      </div>
    )
  })

  let staff: JSX.Element[] = []
  details.staff.forEach((staffPerson: Person, index: number) => {
    let personImage: JSX.Element[] = [];

    if(staffPerson.image != 'false'){
      personImage.push(
        <div className='person-image' style={{backgroundImage: `url(${staffPerson.image})`}}></div>
      );
    }

    if(props.foundSearchedPerson === false && props.searchedPerson == staffPerson.ID && props.showMenu){
      searchedPersonInfo.push(
        <div className="searched-person">
          <h4 className="obj-title">Searched person:</h4>
          {personImage}
          <div className="person-name">{staffPerson.name}</div>
          <div><span>Phone:</span> {staffPerson.phoneNumber}</div>
          <div><span>Email:</span> <a href={'mailto:' + staffPerson.email}>{staffPerson.email}</a></div>
        </div>
      );

      props.foundSearchedPerson = true;
    }

    staff.push(
      <div key={index} className="person">
        <div>{personImage}</div>
        <div>
          <div className='name'>{staffPerson.name}</div>
          <div className='position'>{staffPerson.position}</div>
          <div>{staffPerson.phoneNumber}</div>
          <div><a href={'mailto:' + staffPerson.email}>{staffPerson.email}</a></div>
        </div>
      </div>
    )
  });

  const windowSize = window.innerWidth
  if (windowSize < 1024) {
    return (
      <div
        className={classNames({
          parishDetails: true,
          // animated: props.hasMenuAnimated,
          // hide: props.hasMenuAnimated && !props.showMenu,
          hide: !props.showMenu,
        })}
      >
        <div className="content">
          {requesting && <div className="spinner">
              <FontAwesomeIcon icon={faSpinner} spin />
            </div>}
          {!requesting && <>
            <div className="title">{details.name}</div>
            <button className="close" onClick={props.closeMenu}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
            {details.image && (
              <div className="d-flex flex-column">
                <div
                  className="parish-image"
                  style={{
                    backgroundImage: `url(${details.image})`,
  
                  }}
                ></div>
                <div className="d-flex justify-content-between flex-1 contact-buttons mt-4 mb-4">
                  <a
                    href={`tel:${details.phoneNumber}`}
                    className="width-percent-30"
                  >
                    <button className="button__default-secondary__grey my-2 p-4 width-percent-100 d-flex flex-column justify-content-center align-items-center">
                      <FontAwesomeIcon
                        className="mr-3"
                        icon={faPhone}
                        flip={'horizontal'}
                      />
                      Phone
                    </button>
                  </a>
                  <a
                    href={generateDirections(
                      props.locationForDirections,
                      details.location
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="width-percent-30"
                  >
                    <button className="button__default-secondary__grey my-2 p-4 width-percent-100 d-flex flex-column justify-content-center align-items-center">
                      <FontAwesomeIcon className="mr-3" icon={faMapSigns} />
                      Directions
                    </button>
                  </a>

                  {details.url && (
                    <a href={'mailTo://' + details.email} className="width-percent-30">
                      <button className="button__default-secondary__grey my-2 p-4 width-percent-100 d-flex flex-column justify-content-center align-items-center">
                        <FontAwesomeIcon className="mr-3" icon={faEnvelope} />
                        Email
                      </button>
                    </a>
                  )}

                </div>
              </div>
            )}
          </>}
          <Tabs defaultIndex={2}>
            <TabList className="menu-tabs-mobile">
              <Tab>Information</Tab>
              { details.extraPrograms.length > 0 &&
              <Tab>Programs</Tab> }
              
              <Tab >Contact</Tab>
            </TabList>
            {/* Information Tab Mobile */}
            <TabPanel selectedClassName="tabs-content-mobile">
              {!requesting && (
                <div className="columns mb-5">
                  <div>
                    <div className="mass">{massTimes}</div>

                    <h3>Associated Schools</h3>
                    <div className="schools">{schools}</div>

                    <h3>Notes</h3>
                    <div className="notes">{details.notes}</div>

                    <h3>Land Acknowledgement</h3>
                    <div className="landAcknowledgement">{details.landAcknowledgement}</div>
                  </div>
                </div>
              )}
            </TabPanel>
            {details.extraPrograms.length > 0 &&
              <TabPanel selectedClassName="tabs-content-mobile">
                <div className="features ml-4">
                  {details.extraPrograms.includes("Children's Liturgy") && (
                    <div>
                      <FontAwesomeIcon icon={faChild} />
                      Childrens' Liturgy
                      <FontAwesomeIcon
                        className="features__icon"
                        icon={faInfoCircle}
                      />
                    </div>
                  )}
                  {details.extraPrograms.includes('Young Adults Liturgy') && (
                    <div>
                      <FontAwesomeIcon icon={faUsers} />
                      Young Adults
                      <FontAwesomeIcon
                        className="features__icon"
                        icon={faInfoCircle}
                      />
                    </div>
                  )}
                  {details.extraPrograms.includes('Marriage Prep') && (
                    <div>
                      <FontAwesomeIcon icon={faVenusMars} />
                      Marriage Prep
                      <FontAwesomeIcon
                        className="features__icon"
                        icon={faInfoCircle}
                      />
                    </div>
                  )}
                  {details.extraPrograms.includes('RCIA') && (
                    <div>
                      <FontAwesomeIcon icon={faBook} />
                      RCIA
                      <FontAwesomeIcon
                        className="features__icon"
                        icon={faInfoCircle}
                      />
                    </div>
                  )}
                </div>
              </TabPanel>
            }
            <TabPanel selectedClassName="tabs-content-mobile">
              <div>
                <div className="teeeeeeeeeee">
                  {searchedPersonInfo}
                </div>
                <div>



                <div className="contact searched-person">
                  <h4 className="obj-title">Parish Contact Info:</h4>
                  {details.location.address && (
                    <div>
                      {/* <h3>Street Address</h3> */}
                      <FontAwesomeIcon icon={faMapMarkerAlt} />
                      {details.location.address}
                    </div>
                  )}
                  {details.phoneNumber && (
                    <div>
                      <FontAwesomeIcon icon={faPhone} />
                      <a href={'tel:' + details.phoneNumber}>{details.phoneNumber}</a>
                      {/* {details.phoneNumber} */}
                    </div>
                  )}
                  {details.faxNumber && (
                    <div>
                      <FontAwesomeIcon icon={faFax} />
                      {details.faxNumber}
                    </div>
                  )}
                  {details.url && (
                    <div>
                      <FontAwesomeIcon icon={faGlobeAmericas} />
                      <a href={details.url}>{details.url}</a>
                    </div>
                  )}
                  {details.email && (
                    <div>
                      <FontAwesomeIcon icon={faEnvelope} />
                      <a href={'mailTo:' + details.email}>{details.email}</a>
                    </div>
                  )}
                </div>



                  {/* <div>
                    <Tabs
                      selectedIndex={mailTabIndex}
                      onSelect={(mailTabIndex: number) =>
                        setMailTabIndex(mailTabIndex)
                      }
                      className="columns mb-3"
                    >
                      <TabList>
                        <Tab>
                          <FontAwesomeIcon icon={faMapMarkerAlt} />
                        </Tab>


                        {details.phoneNumber && (
                          <Tab>
                            <FontAwesomeIcon icon={faPhone} />
                          </Tab>
                        )}
                        {details.faxNumber && (
                          <Tab>
                            <FontAwesomeIcon icon={faFax} />
                          </Tab>
                        )}
                        {details.url && (
                          <Tab>
                            <FontAwesomeIcon icon={faGlobeAmericas} />
                          </Tab>
                        )}
                        {details.email && (
                          <Tab>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </Tab>
                        )}
                        {{details.mailAddress && (
                          <Tab>
                            <FontAwesomeIcon icon={faEnvelope} />
                          </Tab>
                        )}
                        {details.officeAddress && (
                          <Tab>
                            <FontAwesomeIcon icon={faBuilding} />
                          </Tab>
                        )}}
                      </TabList>
                      <TabPanel>
                        <h3>Street Address</h3>
                        <div>{details.location.address}</div>
                      </TabPanel>

                      {details.phoneNumber && (
                          <TabPanel>
                            {details.phoneNumber}
                          </TabPanel>
                        )}
                        {details.faxNumber && (
                          <TabPanel>
                            {details.faxNumber}
                          </TabPanel>
                        )}
                        {details.url && (
                          <TabPanel>
                            <a href={details.url}>{details.url}</a>
                          </TabPanel>
                        )}
                        {details.email && (
                          <TabPanel>
                            <a href={'mailTo://' + details.email}>{details.email}</a>
                          </TabPanel>
                        )}

                      { {details.mailAddress && (
                        <TabPanel>
                          <h3>Mailing Address</h3>
                          <div>{details.mailAddress}</div>
                        </TabPanel>
                      )}
                      {details.officeAddress && (
                        <TabPanel>
                          <h3>Office Address</h3>
                          <div>{details.officeAddress}</div>
                        </TabPanel>
                      )}}
                    </Tabs>
                  </div> */}

                  <div className="columns mx-3">
                    <Tabs
                      selectedIndex={tabIndex}
                      onSelect={(tabIndex: number) => setTabIndex(tabIndex)}
                      className="peopleTabs width-percent-100"
                    >
                      <TabList>
                        <Tab>Clergy</Tab>
                        <Tab>Parish Staff</Tab>
                      </TabList>
                      <TabPanel>{clergy}</TabPanel>
                      <TabPanel>{staff}</TabPanel>
                    </Tabs>
                  </div>
                </div>

              </div>
            </TabPanel>
          </Tabs>
        </div>
        {/* {props.showMenu && <Overlay onClick={props.closeMenu} />} */}
      </div>
    )
  }

  // console.log(clergy);
  // console.log(staff);
  // console.log(details);
  // console.log(requesting);


  // const menuRef = React.useRef<HTMLDivElement>(null);
  // const footer = document.getElementsByClassName('site-footer') as HTMLCollectionOf<HTMLElement>;
  
  // const [menuHeight, setMenuHeight] = useState('');

  // useEffect(() => {
  //   if(menuRef.current){
  //     // console.log(`offsetHeight = ${menuRef.current.offsetHeight} offsetTop = ${menuRef.current.offsetTop} total = ${menuRef.current.offsetHeight + menuRef.current.offsetTop}`);
  //     // console.log(footer[0].offsetTop);
  //     console.log(menuHeight);

  //     let height = menuRef.current.offsetHeight + menuRef.current.offsetTop - 200;
  //     let heightPx = height + 'px !important';
  //     setMenuHeight(heightPx);
  //   }
  // },[]);



  return (

    <div
      // style={{maxHeight: menuHeight !== '' ? menuHeight : 'unset'}}
      style={{overflowY: menuVerticalScroll ? 'scroll' : 'unset'}}
      // ref={menuRef}
      ref={parishDetailsRef}
      className={classNames({
        parishDetails: true,
        // animated: props.hasMenuAnimated,
        // hide: props.hasMenuAnimated && !props.showMenu,
        hide: !props.showMenu,
      })}
      >
      <div
        className="content parish-content"
        ref={parishContentRef}
      >
        {!requesting && details.id >= 0 && <div className="title">{details.name}</div>}

        <button className="close" onClick={props.closeMenu}>
          <FontAwesomeIcon icon={faTimes} />
        </button>

        {requesting && (
          <div className="spinner">
            <FontAwesomeIcon icon={faSpinner} spin />
          </div>
        )}

        {!requesting && details.id >= 0 && (
          <div className="columns">
            <div>
              {details.image && <div style={{
                backgroundImage: `url(${details.image})`
              }}
                className="parish-image">
              </div>
              }

              <div className="mass">{massTimes}</div>
              {schools.length > 0 && (
                <div>
                  <h3>Associated Schools</h3>
                  <div className="schools">{schools}</div>
                </div>
              )}
              {details.extraPrograms.length > 0 && (
                <div className="features">
                  {details.extraPrograms.includes("Children's Liturgy") && (
                    <div>
                      <FontAwesomeIcon icon={faChild} />
                      Childrens' Liturgy
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="ml-auto margin-right10"
                      />
                    </div>
                  )}
                  {details.extraPrograms.includes('Young Adults Liturgy') && (
                    <div>
                      <FontAwesomeIcon icon={faUsers} />
                      Young Adults
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="ml-auto margin-right10"
                      />
                    </div>
                  )}
                  {details.extraPrograms.includes('Marriage Prep') && (
                    <div>
                      <FontAwesomeIcon icon={faVenusMars} />
                      Marriage Prep
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="ml-auto margin-right10"
                      />
                    </div>
                  )}
                  {details.extraPrograms.includes('RCIA') && (
                    <div className="mb-3">
                      <FontAwesomeIcon icon={faBook} />
                      RCIA
                      <FontAwesomeIcon
                        icon={faInfoCircle}
                        className="ml-auto margin-right10 "
                      />
                    </div>
                  )}
                </div>
              )}
              {details.notes && (
                <div>
                  <h3>Notes</h3>
                  <div className="notes">{details.notes}</div>
                </div>
              )}
              {details.landAcknowledgement && (
                <div>
                  <h3>Land Acknowledgement</h3>
                  <div className="landAcknowledgement">{details.landAcknowledgement}</div>
                </div>
              )}
            </div>
            <div>
              <Tabs
                selectedIndex={mailTabIndex}
                onSelect={(mailTabIndex: number) =>
                  setMailTabIndex(mailTabIndex)
                }
                className="addressTabs react-tabbed-menus"
              >
                <TabList>
                  <Tab>
                    Street
                    {/* <FontAwesomeIcon icon={faMapMarkerAlt} /> */}
                  </Tab>
                  {details.mailAddress && (
                    <Tab>
                      Mailing
                      {/* <FontAwesomeIcon icon={faEnvelope} /> */}
                    </Tab>
                  )}
                  {details.officeAddress && (
                    <Tab>
                      Office
                      {/* <FontAwesomeIcon icon={faBuilding} /> */}
                    </Tab>
                  )}
                </TabList>
                <TabPanel>
                  <h3 className="margin-bot-10">Street Address</h3>
                  <div className="margin-bot-10">{details.location.address}</div>
                  <a
                    href={generateDirections(
                      props.locationForDirections,
                      details.location
                    )}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <button className="button__default-secondary__grey btn-style-1 my-2 p-4">
                      {/* <FontAwesomeIcon className="mr-3" icon={faMapSigns} /> */}
                      <FontAwesomeIcon className="mr-3 color-primary" icon={faMapMarkerAlt} />
                      {/* <i class="fas fa-map-marker-alt"></i> */}
                      Directions
                    </button>
                  </a>
                </TabPanel>
                {details.mailAddress && (
                  <TabPanel>
                    <h3>Mailing Address</h3>
                    <div>{details.mailAddress}</div>
                  </TabPanel>
                )}
                {details.officeAddress && (
                  <TabPanel>
                    <h3>Office Address</h3>
                    <div>{details.officeAddress}</div>
                  </TabPanel>
                )}
              </Tabs>

              <div className="contact">
                {details.phoneNumber && (
                  <div>
                    <FontAwesomeIcon icon={faPhone} />
                    {details.phoneNumber}
                  </div>
                )}
                {details.faxNumber && (
                  <div>
                    <FontAwesomeIcon icon={faFax} />
                    {details.faxNumber}
                  </div>
                )}
                {details.url && (
                  <div>
                    <FontAwesomeIcon icon={faGlobeAmericas} />
                    <a href={details.url}>{details.url}</a>
                  </div>
                )}
                {details.email && (
                  <div>
                    <FontAwesomeIcon icon={faEnvelope} />
                    <a href={'mailTo://' + details.email}>{details.email}</a>
                  </div>
                )}
              </div>


              <div className="teeeeeeeeeee">
                {searchedPersonInfo}
              </div>


              <div>
                <Tabs
                  selectedIndex={tabIndex}
                  onSelect={(tabIndex: number) => setTabIndex(tabIndex)}
                  className="peopleTabs react-tabbed-menus"
                >
                  <TabList>
                    <Tab>Clergy</Tab>
                    <Tab>Parish Staff</Tab>
                  </TabList>
                  <TabPanel>{clergy}</TabPanel>
                  <TabPanel>{staff}</TabPanel>
                </Tabs>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* {props.showMenu && <Overlay onClick={props.closeMenu} />} */}
    </div>
  )
}

export default Menu
