import * as React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTimes } from '@fortawesome/free-solid-svg-icons'
var classNames = require('classnames')

export interface IMoreDetailsProps {
  isOpen: boolean
  close: () => void
  description: string
  image: string | undefined
  registerLink: string | undefined
  pageLink: string
  isAnimated: boolean
}

export const MoreDetails = (props: IMoreDetailsProps) => {
  return (
    <div
      className={classNames('row mt-5 bottom-border', {
        hidden: !props.isOpen,
        animated: props.isAnimated,
      })}
    >
      <div className='col-10 offset-2 d-flex flex-column'>
        <div className='row d-flex justify-content-between'>
          <h6>Event Details</h6>
          <p className='clickable' onClick={() => props.close()}>
            <FontAwesomeIcon icon={faTimes} size={'2x'} />
          </p>
        </div>
        <div className='row justify-content-center justify-content-md-start'>
          {props.image ? (
            <div
              className='bg-center-contain height-200 width-200'
              style={{ backgroundImage: `url(${props.image})` }}
            ></div>
          ) : (
              <div
                className='col-4'
                style={{
                  backgroundColor: 'grey',
                  width: '100%',
                  height: '150px',
                }}
              ></div>
            )}
          <div className='col-md-8 col-12 mt-5 mt-md-0'>
            {props.description &&
              <div dangerouslySetInnerHTML={{ __html: props.description }}></div>
            }
          </div>
        </div>
      </div>
      {props.registerLink ? (
        <div
          className='d-flex padding-top7 padding-bottom7 width-percent-100 justify-content-center mt-3'
          style={{ backgroundColor: 'grey' }}
        >
          <div className='d-flex flex-column mr-5'>
            <h6>Event Registration</h6>
            <p className='mb-0'>Registration deadline is ______________</p>
          </div>
          <button className='button__default-primary ml-5'>Register Now</button>
        </div>
      ) : null}
      {/* This is the link to the actual page. Will leave here in case they want to create a separate page for an event */}
      {/* <div className='row col-10 offset-2 d-flex flex-column flex-md-row align-items-center justify-content-center width-percent-100 py-5'>
        <h6 className='mr-md-5'>Even more details</h6>
        <a className='link ml-md-5' href={props.pageLink}>
          Go to Event Page
        </a>
      </div> */}
    </div>
  )
}
