import React from 'react'

import { Time } from './Types'

interface Props {
  time: Time
}

const MassTime = (props: Props) => {
  let disclaimer = ''
  if (!props.time.weekly && props.time.weeks) {
    if (props.time.weeks.length === 1) {
      disclaimer = `${props.time.weeks[0]} ${props.time.day} of the month`
    } else if (props.time.weeks.length >= 2) {
      for (let i = 0; i < props.time.weeks.length; i++) {
        disclaimer += props.time.weeks[i]
        if (i !== props.time.weeks.length - 1) {
          disclaimer += ', '
        }
      }
      disclaimer += ` ${props.time.day} of the month`
    }
  }

  return (
    <div className="time-entry alt-grey-background">
      <div className="time">
        {!props.time.weekly && <div className="disclaimer">{disclaimer}</div>}
        {props.time.weekly && <div>{props.time.day}</div>}
        <div>{props.time.time}</div>
        <div>{props.time.language}</div>
      </div>
    </div>
  )
}

export default MassTime
