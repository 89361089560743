import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconDefinition } from '@fortawesome/free-solid-svg-icons'

interface CheckboxProps {
  label: string
  icon?: IconDefinition
  value: boolean
  onClick: (arg0: null | React.ChangeEvent<HTMLInputElement>) => void
  classes?: string
}

const Checkbox = (props: CheckboxProps) => {
  let checkboxClasses = 'checkbox-custom-label'
  if (props.classes) {
    checkboxClasses += ' ' + props.classes
  }

  return (
    <div className="value">
      <input
        name="checkbox-default"
        id={'checkbox' + props.label + props.classes}
        className="checkbox-custom"
        type="checkbox"
        checked={props.value}
        onChange={props.onClick}
      />
      <label
        className={checkboxClasses}
        htmlFor={'checkbox' + props.label + props.classes}
      >
        {props.icon && <FontAwesomeIcon icon={props.icon} />}
        <div className="label">{props.label}</div>
      </label>
    </div>
  )
}

export default Checkbox;
