import React, { useState } from 'react'
import moment, { Moment } from 'moment'
import { IEventObj } from '../../interfaces/interfaces'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { MoreDetails } from '../../event-calendar/MoreDetails'
import './singleEventCard.scss'
import AddToCalendar, { SHARE_SITES } from 'react-add-to-calendar-hoc'

export interface ISingleEventCardProps {
  event: IEventObj
}

export const SingleEventCard = (props: ISingleEventCardProps) => {
  const [isMoreDetailsOpen, setIsMoreDetailsOpen] = useState<boolean>(false)
  const [isAnimated, setIsAnimated] = useState<boolean>(false)

  const formatStartEndTimes = (start: Moment, end: Moment) => {
    let startEndOutput = start.format('HHmm')
    if (start.date() === end.date()) {
      return (startEndOutput += ` - ${end.format('HHmm')}`)
    } else {
      return (startEndOutput += ` - ${end.format('HHmm')} on ${end.format(
        'ddd MMMM Do'
      )}`)
    }
  }

  const { event } = props
  const startDateTime = moment(event.start_time)
  const endDateTime = moment(event.end_time)
  const parishName = event.location ? event.location.name : ''
  const address = event.location ? event.location.address : ''

  const closeMoreDetails = () => {
    setIsMoreDetailsOpen(false)
    setIsAnimated(!isAnimated)
  }

  const Button = (props: any) => {
    return (
      <button className='add-to-calendar' onClick={props.onClick}>
        Add To Calendar
      </button>
    )
  }

  interface ICalendarModal {
    children: JSX.Element
  }
  const CalendarModal = (props: ICalendarModal) => {
    return <div className='d-inline-block'>: {props.children}</div>
  }
  const AddToCalendarModal = AddToCalendar(Button, CalendarModal)

  const eventPassed = {
    title: event.post_title,
    description: event.event_content,
    location: event.location.address,
    startDatetime: startDateTime.format('YYYYMMDDTHHmmss'),
    endDatetime: endDateTime.format('YYYYMMDDTHHmmss'),
    duration: '1',
  }

  return (
    <div className='container-fluid my-5 px-1'>
      <div className='row'>
        <div className='col-2 border-right d-flex flex-column justify-content-center pr-3 text-center'>
          <p className='my-0 text-small-mobile'>
            {startDateTime.format('dddd')}
          </p>
          <h4 className='my-0'>{startDateTime.format('MMM')}</h4>
          <h4 className='my-0'>{startDateTime.format('DD')}</h4>
        </div>
        <div className='col-10'>
          <h4 className='d-inline'>
            <strong>{event.post_title}</strong>
          </h4>
          <p className='d-inline float-right'>
            <em className='mr-5'>Type:</em>
            {event.event_type.name}
          </p>
          <p>{formatStartEndTimes(startDateTime, endDateTime)}</p>
          <div className='row'>
            <FontAwesomeIcon icon={faMapMarker} className='ml-5' />
            <div className='pl-5 d-inline-block'>
              <p className='mb-0'>{parishName}</p>
              <p className='mb-0'>{address}</p>
            </div>
          </div>
          <div className='row justify-content-between mx-3 mt-5'>
            <AddToCalendarModal
              event={eventPassed}
              linkProps={{
                className: 'calendarLink',
              }}
              items={[
                SHARE_SITES.GOOGLE,
                SHARE_SITES.ICAL,
                SHARE_SITES.OUTLOOK,
              ]}
            />

            <div
              className='pr-4 more-details'
              onClick={() => setIsMoreDetailsOpen(!isMoreDetailsOpen)}
            >
              <a className='link'>More Details</a>
            </div>
          </div>
        </div>
      </div>
      <MoreDetails
        isAnimated={isAnimated}
        isOpen={isMoreDetailsOpen}
        close={closeMoreDetails}
        description={event.event_content}
        image={event.image}
        registerLink={event.register_link}
        pageLink={event.event_link}
      />
    </div>
  )
}
