import React, { useEffect, useState } from 'react'
import 'react-dates/initialize'
import 'react-dates/lib/css/_datepicker.css'
import { DayPickerSingleDateController } from 'react-dates'
import moment, { Moment } from 'moment'
import WPApi from '../api/events'
import { SingleEventCard } from '../components/SingleEventCard/SingleEventCard'
import { IEventObj, IEventType } from '../interfaces/interfaces'
import { isSameDay } from '../utils/isSameDay'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faList, faCalendarAlt } from '@fortawesome/free-solid-svg-icons'
import './EventCalendar.scss'

interface IEventCalendarProps { }

const initialState = {
  date: null,
  focused: false,
  highlightedDays: [],
  isLoading: true,
  allEvents: [],
  filteredEvents: undefined,
  eventsOnSelectedDate: [],
  eventFilters: [],
  currentFilter: undefined,
  isListActive: false,
  isCalendarActive: true,
  daySize: 60,
}

export const EventCalendar = (props: IEventCalendarProps) => {
  const [date, setDate] = useState<Moment | null>(initialState.date)
  const [focused, setFocused] = useState<boolean>(initialState.focused)
  const [highlightedDays, setHighlightedDays] = useState<moment.Moment[]>(
    initialState.highlightedDays
  )
  const [isLoading, setIsLoading] = useState<boolean>(initialState.isLoading)
  const [allEvents, setAllEvents] = useState<IEventObj[]>(
    initialState.allEvents
  )
  const [filteredEvents, setFilteredEvents] = useState<undefined | IEventObj[]>(
    initialState.filteredEvents
  )
  const [eventsOnSelectedDate, setEventsOnSelectedDate] = useState<IEventObj[]>(
    initialState.eventsOnSelectedDate
  )

  const [eventFilters, setEventFilters] = useState<IEventType[]>(
    initialState.eventFilters
  )
  const [currentFilter, setCurrentFilter] = useState<string | undefined>(
    initialState.currentFilter
  )
  const [isListActive, setIsListActive] = useState<boolean>(
    initialState.isListActive
  )
  const [isCalendarActive, setIsCalendarActive] = useState<boolean>(
    initialState.isCalendarActive
  )
  const [daySize, setDaySize] = useState<number>(initialState.daySize)

  const ALL = 'all'

  // This needs to be done on onMount
  useEffect(() => {
    setDateCellSize()
    const getEventsDetails = async () => {
      let events: IEventObj[] = await WPApi.getAllEvents()
      events = populateRecurringEvents(events)
      const eventDays = highlightEvents(events)
      const eventTypes = await WPApi.getAllEventFilters();

      // const eventFilters: IEventType[] = eventTypes.filter(
      //   (type: IEventType) => {
      //     return type.count > 0
      //   }
      // )
      const eventFilters: IEventType[] = eventTypes;

      eventFilters.unshift({
        id: 0,
        name: 'All',
        slug: ALL,
        count: 1,
      });

      let today = moment()
      let closestDateWithEvent: Moment = moment('2199-01-01')

      events.some((event: IEventObj) => {
        let eventDate = moment(event.start_time)
// console.log(`eventDate=${eventDate}`);
// console.log(`event.start_time=${event.start_time}`);
//         closestDateWithEvent = eventDate;
// return true;

        // if(index < 10 ){
        //   closestDateWithEvent = eventDate;
        //   return true;
        // }
        // else{
        //   closestDateWithEvent = eventDate;
        //   return false;
        // }

        //keep results that end today only
        if (isSameDay(today, eventDate)) {
          closestDateWithEvent = eventDate
          return true
        } else {
          if (eventDate.isBefore(today)) return false
          if (closestDateWithEvent && closestDateWithEvent.isBefore(eventDate))
            return false
          closestDateWithEvent = today;//eventDate
        }
      })

      setAllEvents(events)
      setHighlightedDays(eventDays)
      setEventFilters(eventFilters)
      setIsLoading(false)
      setDaySize(getDateSize(window.innerWidth))
      // setDate(closestDateWithEvent)
      setDate(today);

      setEventsOnSelectedDate(
        renderEventsOnDate(events, parseMomentToString(closestDateWithEvent))
      )
    }

    getEventsDetails()
  }, [])

  // console.log(eventFilters);

  // Individual events in a recurring sequence are not all added to the WP database, only the original event.
  // Here we are creating events that would be the future events based on settings of the original event.
  const newRecurringEvent = (event: IEventObj, start_time: string, end_time: string, event_from_start: number) => {
    const frequency = event.recur_frequency

    // Which event is this relative to the first event saved
    const time_periods = event_from_start * (event.recur_every ? event.recur_every : 0)

    let new_start_time = frequency === 'monthly' ? parseMomentToString(moment(start_time).add(time_periods, 'months')) :
      parseMomentToString(moment(start_time).add(time_periods, 'weeks'))

    let new_end_time = frequency === 'monthly' ? parseMomentToString(moment(end_time).add(time_periods, 'months')) :
      parseMomentToString(moment(end_time).add(time_periods, 'weeks'))

    return {
      post_title: event.post_title,
      event_content: event.event_content,
      location: event.location,
      is_event_recurring: false,
      start_time: new_start_time,
      end_time: new_end_time,
      event_link: event.event_link,
      event_type: event.event_type,
      image: event.image,
      register_link: event.register_link
    }
  }

  const populateRecurringEvents = (events: IEventObj[]) => {
    const returned_events = events
    events.forEach((event: IEventObj) => {
      if (event.is_event_recurring && event.recur_length) {
        for (let x = 1; x < event.recur_length; x++) {
          returned_events.push(newRecurringEvent(event, event.start_time, event.end_time, x))
        }
      }
    })

    return returned_events
  }




  const highlightEvents = (events: IEventObj[]) => {
    return events.map((event: IEventObj) => {
      return moment(event.start_time)
    })
  }

  const renderEventsOnDate = (events: IEventObj[], date: string) => {
    return events.filter((event: IEventObj) => {
      return event.start_time.includes(date)
    })
  }

  // Helper function to only return the fields of the date that we need to determine where
  // in the calendar it will render.
  const parseMomentToString = (date: Moment): string => {
    return date.format('YYYY-MM-DD')
  }

  // Finds all events on given day that match the filtered events (or all events
  // in case of 'All' filter)
  const currentDayEvents = (date: Moment | null) => {
    if (date) {
      const dateSelected = parseMomentToString(date)
      return renderEventsOnDate(
        filteredEvents ? filteredEvents : allEvents,
        dateSelected
      )
    } else {
      return []
    }
  }

  const onFilterChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    // If the 'All' select option is picked, reset fileteredEvents to all events. Once that is updated,
    // find all the events within that that match the current date.
    if (e.target.value === ALL) {
      const eventDays = highlightEvents(allEvents)

      setFilteredEvents(allEvents)
      setHighlightedDays(eventDays)
      return
    }

    // Find all the events that match the event slug with the value from the select menu.
    // Once the new events are
    const filteredEvents = allEvents.filter((event: IEventObj) => {
      return event.event_type.slug === e.target.value
    })

    const eventDays = highlightEvents(filteredEvents)
    setFilteredEvents(filteredEvents)
    setHighlightedDays(eventDays)
  }

  useEffect(() => {
    const eventsOnSelectedDate = currentDayEvents(date)
    setEventsOnSelectedDate(eventsOnSelectedDate)
  }, [filteredEvents, highlightedDays])

  const onDateChange = (date: Moment | null) => {
    let eventsOnSelectedDate: IEventObj[] = currentDayEvents(date)

    setEventsOnSelectedDate(eventsOnSelectedDate) // finds events that match the date selected
    setDate(date) // selects the date that is highlighted on the calendar when clicked

    // * flips to list view when you click on a date in mobile view
    setIsCalendarActive(!isCalendarActive)
    setIsListActive(!isListActive)
  }

  const onFocusChange = () => {
    // Force the focused states to always be truthy so that date is always selectable
    setFocused(true)
  }

  // Flips to calendar or list view in mobile
  const handleListView = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    setIsListActive(!isListActive)
    setIsCalendarActive(!isCalendarActive)
  }

  const getWidth = () => {
    return window.innerWidth
  }
  // Figures out the size of the day cell in calendar based on screen width
  const getDateSize = (width: number) => {
    if (width > 1199) return 60
    else if (width > 700 && width < 1199) return Math.round(width / 11)
    else return Math.round(width / 8)
  }

  const setDateCellSize = () => {
    window.addEventListener('resize', () => {
      const screenSize = getWidth()

      setDaySize(getDateSize(screenSize))
    })
  }

  if (isLoading) return <p>Loading...</p>
  else {
    return (
      <div className='App container-fluid min-height-800-mobile mb-5'>
        <div className='row flex-wrap align-items-center'>
          <h1 className='col-6 col-sm-2 order-1'>Events</h1>
          <form className='col-12 col-sm-8 p-2 order-3 order-sm-2'>
            <ul className='select mx-auto my-5 p-0'>
              <li>
                <input
                  className='select_close'
                  type='radio'
                  name='event-filter'
                  id='default-close'
                  value=''
                />
                <span className='select_label select_label-placeholder'>
                  Filter events by:
                </span>
              </li>
              <li className='select_items'>
                <input
                  className='select_expand'
                  type='radio'
                  name='event-filter'
                  id='event-filter-opener'
                />
                <label className='select_closeLabel' htmlFor='default-close' />
                <ul className='select_options'>
                  {eventFilters &&
                    eventFilters.map((filter, index) => (
                      <li className='select_option' key={index}>
                        <input
                          className='select_input'
                          type='radio'
                          name='event-filter'
                          id={`event-filter-${index}`}
                          onChange={onFilterChange}
                          value={filter.slug}
                        />
                        <label
                          className='select_label'
                          htmlFor={`event-filter-${index}`}
                        >
                          {filter.name}
                        </label>
                      </li>
                    ))}
                </ul>
                <label
                  className='select_expandLabel'
                  htmlFor='event-filter-opener'
                />
              </li>
            </ul>
          </form>
          <div className='d-none col-6 col-sm-2 justify-content-end px-0 order-2 order-sm-3 pr-5'>
            <button
              className={isListActive ? 'menu-button active' : 'menu-button'}
              onClick={handleListView}
            >
              <div id='list-view' className='d-inline-block'>
                <FontAwesomeIcon icon={faList} size='3x' className='p-3' />
              </div>
            </button>
            <button
              className={
                isCalendarActive ? 'menu-button active' : 'menu-button'
              }
              onClick={handleListView}
            >
              <div id='calendar-view' className='d-inline-block'>
                <FontAwesomeIcon
                  icon={faCalendarAlt}
                  size='3x'
                  className='p-3'
                />
              </div>
            </button>
          </div>
        </div>
        <div className='row'>
          <div
            className={
              isListActive ? 'col-12 col-xl-7' : 'col-12 col-xl-7'
            }
          >
            <ul id='daily-events-listing' className='m-0'>
            {eventsOnSelectedDate.length > 0 && moment() === date && <h2>Today's events:</h2>}
            {eventsOnSelectedDate.length > 0 && moment() !== date && date != null && <h2>Event(s) coming on {date.format('dddd MMM D')}:</h2>}
            
              {eventsOnSelectedDate.length > 0 &&
                eventsOnSelectedDate.map(event => (
                  <div key={`${event.post_title}${event.start_time}`}>
                    <SingleEventCard event={event} />
                  </div>
                ))}
              {eventsOnSelectedDate.length === 0 && (
                <div className='message mt-5 mb-5'>
                  <p className='m-auto'>There are no events on this date.</p>
                </div>
              )}
              <div>
                <h2>Events in the next 7 days:</h2>
                {allEvents.length > 0 &&
                  allEvents.map(event => (
                    !isSameDay(moment(), moment(event.start_time)) && moment(event.start_time) > moment() && moment(event.start_time) < moment().add(1, 'weeks' ) &&
                    <div key={`${event.post_title}${event.start_time}`}>
                      <SingleEventCard event={event} />
                    </div>
                ))}
              </div>
            </ul>
          </div>

          <div
            className={
              isCalendarActive
                ? 'col-12 col-xl-5 p-2 d-flex justify-content-center'
                : 'col-12 col-xl-5 p-2 d-flex justify-content-center caedm-d-none'
            }
          >
            {/* To prevent the calendar from expanding, wrap it in a div and calc height based on width */}
            <div style={{ height: `${getWidth() * 0.85}` }}>
              <DayPickerSingleDateController
                onDateChange={onDateChange}
                onFocusChange={onFocusChange}
                focused={focused}
                date={date}
                isDayHighlighted={day1 =>
                  highlightedDays.some((day2: Moment) => isSameDay(day1, day2))
                }
                hideKeyboardShortcutsPanel={true}
                daySize={daySize}
              />
            </div>
          </div>
        </div>
      </div>
    )
  }
}
