import React from 'react'

import { GoogleMap, Marker, withGoogleMap, InfoWindow } from 'react-google-maps'
import { Parish, Location } from './Types'

import {
  EdmontonCoordinates,
  LocationOptions,
} from '../constants/LocationOptions'

interface Props {
  isSearchedRecord: boolean
  range: number
  isMarkerShown: boolean
  mapCircle: google.maps.Circle | undefined
  parishes: Parish[]
  clicked: number
  userLocation?: Location
  center: google.maps.LatLng
  zoom: number
  onBoundsChanged: (
    bounds: google.maps.LatLngBounds,
    center: google.maps.LatLng
  ) => void
  onZoomChanged: (zoom: number) => void
  onMarkerClicked: (parish: Parish) => void
  byAddressLocation?: Location
  hovered: number
  updateBoundsParishList: (boundsParishesList: Parish[]) => void,
}

const MyMapComponent = (props: Props) => {

  const mapRef = React.createRef<GoogleMap>()

  const onBoundsChanged = () => {
    if (mapRef.current != null) {
      props.onBoundsChanged(
        mapRef.current.getBounds(),
        mapRef.current.getCenter()
      )
    }
  }

  const onZoomChanged = () => {
    if (mapRef.current != null) {
      props.onZoomChanged(mapRef.current.getZoom())
    }
  }

  const opacityLevel = (parish: Parish) => {
    return(
      props.hovered === parish.id || props.clicked === parish.id || props.isSearchedRecord
      ? 1.0
      : 0.5
    );
  }

  //each parish (on the map and in this sidebar list) will have a "id" label (similar to the common store locator labels on other sites
  let labels = 1;
  // const labels = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  // let labelIndex = 0;

  //get a list of all parishes within the map's visible bounds
  const parishes: JSX.Element[] = []
  const parishesInRadius: JSX.Element[] = [];
  const parishesInRadiusList: Parish[] = [];
  
  props.parishes.forEach((parish: Parish, index: number) => {
    const label = { text: String(labels++) }; 
    parishes.push(
      <Marker
        // animation={
        //   props.hovered === parish.id || props.clicked === parish.id
        //     ? google.maps.Animation.BOUNCE
        //     : undefined
        // }
        icon={
          props.hovered === parish.id || props.clicked === parish.id
            ? require('./mapMarker__small_full.png')
            : require('./mapMarker__unselected.png')
        }
        title={parish.name}
        onClick={() => {
          if (mapRef.current) {
            mapRef.current.panTo(
              new google.maps.LatLng(parish.location.lat, parish.location.lng)
            )
          }
          props.onMarkerClicked(parish)
        }}
        key={index}
        position={parish.location}
        label={label}
        labelAnchor={new google.maps.Point(0, 0)}
      >
        <InfoWindow
          key={`infowindow-${parish.name}`}
          options={
            {
              disableAutoPan: true
            }
          }
          >
          <div>{parish.name}</div>
        </InfoWindow>
      </Marker>
    )

    
    //display parish markers only if they are either within the selected KM range in the filter, or if the parishes are the result of a search
    // if(parish.distance != null){
      //parish distance is in meters while props range is in km; compare them while both are in metres
      if(props.isSearchedRecord || parish.distance != null /*&& parish.distance <= props.range * 1000*/){
        //add all parishes within range to a list which will be displayed on the left side of the screen
        parishesInRadiusList.push(parish);

        //create map markers from all parishes in range
        const label = { text: String(labels++) }; 
        parishesInRadius.push(
          <Marker
          // animation={
          //   props.hovered === parish.id || props.clicked === parish.id
          //     ? google.maps.Animation.BOUNCE
          //     : undefined
          // }
          // onMouseOver={(e) => {
          // }}
          opacity={
            // props.hovered === parish.id || props.clicked === parish.id
            // ? 1.0
            // : 0.5
            opacityLevel(parish)
          }
          icon={
            props.hovered === parish.id || props.clicked === parish.id
              ? require('./mapMarker__small_full.png')
              : require('./mapMarker__unselected.png')
          }
          title={parish.name}
          onClick={() => {
            if (mapRef.current) {
              mapRef.current.panTo(
                new google.maps.LatLng(parish.location.lat, parish.location.lng)
              )
            }
            props.onMarkerClicked(parish)
          }}
          key={index}
          position={parish.location}
          label={label}
          labelAnchor={new google.maps.Point(0, 0)}
        >
          <InfoWindow
            key={`infowindow-${parish.name}`}
            options={
              {
                disableAutoPan: true
              }
            }
            >
            <div style={{
              // opacity: props.hovered === parish.id || props.clicked === parish.id
              // ? 1.0
              // : 0.5
              opacity: opacityLevel(parish)
            }}>
              {parish.name}
            </div>
          </InfoWindow>
        </Marker>
        );
      }
    // }
  })

  //iterate through all visible parishes and keep only the ones within the user's specified km radius and then display those on the map;
  //the map zoom will no longer display more parishes if the km range is not changed!!! - yay
  // const parishesInRadius: JSX.Element[] = [];

props.updateBoundsParishList(parishesInRadiusList);

  // if( parishes.length > 0 /*&& props.mapCircle?.getBounds()*/ ){
  //   parishes.forEach( (parish) => {
  //     // console.log('---');
  //     // console.log(props.mapCircle);
  //     // console.log('---');
  //       // console.log(props.mapCircle.getBounds());
  //       // console.log(parish.props.position);

  //       // let mapBounds = props.mapCircle.getBounds() | undefined;

  //     if( props.mapCircle && props.mapCircle.getBounds() !== null ){
  //       // if(parish.props.position){
        
  //         if( props.mapCircle.getBounds().contains(parish.props.position) ){
  //           parishesInRadius.push(parish);
  //         }
  //         else{
  //           // console.log('fail undefined?');
  //         }
          
  //       // }
  //     }
  //   });

  //   props.updateBoundsParishList(parishesInRadius);
  // }

  let user = props.userLocation ? (
    <Marker
      key={parishesInRadius.length}
      position={props.userLocation}
      icon={require('./userLocation.png')}
    />
  ) : null

  // let byAddress = props.byAddressLocation ? (
  //   <Marker
  //     key={props.byAddressLocation.lat}
  //     animation={google.maps.Animation.DROP}
  //     icon={require('./byAddressLocation.png')}
  //     position={props.byAddressLocation}
  //   />
  // ) : null

  return (
    <div>
      <GoogleMap
        ref={mapRef}
        defaultCenter={{ lat: EdmontonCoordinates.lat, lng:EdmontonCoordinates.lng }}
        onBoundsChanged={onBoundsChanged}
        options={{
          zoomControl: true,
          zoomControlOptions: {
            position: google.maps.ControlPosition.RIGHT_TOP,
            style: google.maps.ZoomControlStyle.DEFAULT,
          },
        }}
        center={props.center}
        zoom={props.zoom}
        onZoomChanged={onZoomChanged}
      >
        {props.isMarkerShown && parishesInRadius}
        {/* {byAddress && byAddress} */}
        {/*user && user*/}
      </GoogleMap>
    </div>
  )
}

export default withGoogleMap(MyMapComponent)
