export const MassTypes = {
  Confession: [
    'Adoration',
    'Confession',
    'Confessions',
    'Confession and Adoration',
    'Reconciliation'
  ],
  Mass: [
    'Daily Mass',
    'Divine Worship',
    'High Mass',
    'Holy Qurbana (Mass)',
    'Holy Qurbana and Adoration',
    'Mass',
    'Mass (additional)',
    'Messe',
    'Sudanese Mass',
    'Sung Mass',
    'Swahili Mass',
    'Weekday Mass'
  ],
};
