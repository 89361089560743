import React, { Dispatch, SetStateAction } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faTimes,
  faChild,
  faUsers,
  faVenusMars,
  faBook,
} from '@fortawesome/free-solid-svg-icons'
import NumericInput from 'react-numeric-input'
import { DaysOfTheWeek, IDay } from '../constants/DaysOfTheWeek'
import { ChurchEvents } from '../constants/ChurchEvents'
import { LocationOptions } from '../constants/LocationOptions'
import TimePicker from 'rc-time-picker'
import 'rc-time-picker/assets/index.css'

import Checkbox from '../components/Checkbox'
import Radio from '../components/Radio'
import Overlay from './Overlay'
import LocationSearchInput from '../components/PlacesAutocomplete'
import { Location } from './Types'
import moment, { Moment } from 'moment'

const format = 'h:mm a'

var classNames = require('classnames')

interface Props {
  isMassTodaySelected: boolean
  isMassTomorrowSelected: boolean
  hasAnimated: boolean
  show: boolean
  close: () => void
  onAddressSelect: (location: Location) => void
  massDaysSelected: number[]
  confessionDaysSelected: number[]
  toggleDayOfTheWeek: (event: number, dayOfWeek: number) => void
  isMassSearchActive: boolean
  isDateRadioActive: boolean
  dateRadio: string | undefined
  isConfessionDaysActive: boolean
  massStartTime: Moment | undefined
  massEndTime: Moment | undefined
  confessionStartTime: Moment | undefined
  confessionEndTime: Moment | undefined
  findByLocation: string
  address: string
  range: number
  childrensLiturgy: boolean
  yaLiturgy: boolean
  marriagePrep: boolean
  rcia: boolean
  isValidSearch: boolean
  setMassDaysSelected: Dispatch<SetStateAction<number[]>>
  setConfessionDaysSelected: Dispatch<SetStateAction<number[]>>
  setDateRadio: Dispatch<SetStateAction<string | undefined>>
  setIsDateRadioActive: Dispatch<SetStateAction<boolean>>
  setIsConfessionDaysActive: Dispatch<SetStateAction<boolean>>
  setMassStartTime: Dispatch<SetStateAction<Moment | undefined>>
  setMassEndTime: Dispatch<SetStateAction<Moment | undefined>>
  setConfessionStartTime: Dispatch<SetStateAction<Moment | undefined>>
  setConfessionEndTime: Dispatch<SetStateAction<Moment | undefined>>
  setFindByLocation: Dispatch<SetStateAction<LocationOptions>>
  setIsMassSearchActive: Dispatch<SetStateAction<boolean>>
  setAddress: Dispatch<SetStateAction<string>>
  setRange: Dispatch<SetStateAction<number>>
  setChildrensLiturgy: Dispatch<SetStateAction<boolean>>
  setYaLiturgy: Dispatch<SetStateAction<boolean>>
  setMarriagePrep: Dispatch<SetStateAction<boolean>>
  setRcia: Dispatch<SetStateAction<boolean>>
  setIsValidSearch: Dispatch<SetStateAction<boolean>>
  onChangeByLocation: (e: any) => void
  onRangeChange: (
    value: number | null,
    stringValue: string,
    el: HTMLInputElement
  ) => void
  checkIfDirty: () => true | undefined
  // onDateRadioChanged: (event: React.ChangeEvent<HTMLInputElement>) => void
  onDateRadioChanged: (event: React.ChangeEvent<HTMLInputElement> | null, day: SetStateAction<string | undefined>) => void
  checkIfValidSearch: () => void
  onResetAllFilters: () => void
  onMassStartTimeChange: (value: moment.Moment) => void
  onMassEndTimeChange: (value: moment.Moment) => void
  onAddressUpdate: (address: string) => void
}

let windowSize = window.innerWidth;

// console.log(windowSize);

const Filters = (props: Props) => {
  return (
    <div
      className={classNames({
        searchFilters: true,
        animated: props.hasAnimated,
        // hide: props.hasAnimated && !props.show,
        hide: !props.show,
      })}
    >
      <div className="header height-65 d-flex justify-content-between align-items-center px-4 filter-by">
        <h2 className="title">Mass Time Finder</h2>
        <div>
          {props.checkIfDirty() && (
            <button className="clear button__default-primary" onClick={props.onResetAllFilters}>
              Clear Filters
            </button>
          )}
          {props.isValidSearch && (
            <button className="apply button__default-primary" onClick={props.close}>
              UPDATE
            </button>
          )}
          {/* {!props.isValidSearch && (
            <button className="close" onClick={props.close}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
          )} */}
            <button className="close" onClick={props.close}>
              <FontAwesomeIcon icon={faTimes} />
            </button>
        </div>
      </div>
      <div className="content">
        <div className="">
          <div
            className={classNames('row', {
              active:
                props.dateRadio &&
                props.isDateRadioActive &&
                props.isMassSearchActive,
            })}
          >
            <div className="col-12 d-flex justify-content-between flex-wrap flex-sm-nowrap">
              <div className="mass-dates-radio d-flex align-items-center">
                <div className="label label-title font-weight-bold color-primary">Mass Dates:</div>
                <Checkbox
                  // name="date"
                  key={'Today'}
                  label={'Today'}
                  value={props.isMassTodaySelected}
                  // index={0}
                  // selectedValue={props.dateRadio}
                  onClick={event => {
                    if(event)
                      props.onDateRadioChanged(event, 'Today')
                    }}
                  classes={'vertical-centered mass margin-mobile d-flex'}
                />
                <Checkbox
                  // name="date"
                  key={'Tomorrow'}
                  label={'Tomorrow'}
                  value={props.isMassTomorrowSelected}
                  // index={1}
                  // selectedValue={props.dateRadio}
                  onClick={event => {
                    if(event)
                      props.onDateRadioChanged(event, 'Tomorrow')
                    }}
                  classes={'vertical-centered mass margin-mobile d-flex'}
                />
                {/* <Radio
                  name="date"
                  value="Today"
                  index={0}
                  selectedValue={props.dateRadio}
                  onClick={props.onDateRadioChanged}
                />
                <Radio
                  name="date"
                  value="Tomorrow"
                  index={1}
                  selectedValue={props.dateRadio}
                  onClick={props.onDateRadioChanged}
                /> */}
              </div>
            </div>
          </div>

            <div className='row'>
              {/*  */}
              <div
                className={classNames('col-6', {
                  active: !props.isDateRadioActive && props.isMassSearchActive,
                })}
              >
                <div className="label label-title font-weight-bold color-primary">Mass: Day of the Week</div>
                <div className="radio-list">
                  {DaysOfTheWeek.map((day: IDay, index: number) => (
                    <Checkbox
                      key={day.name + ChurchEvents.Mass}
                      label={day.name}
                      value={props.massDaysSelected.includes(index)}
                      onClick={event => {
                        if (event)
                          props.toggleDayOfTheWeek(ChurchEvents.Mass, index)
                      }}
                      classes={'vertical-centered mass margin-mobile d-flex'}
                    />
                  ))}
                </div>


                            <div
                              className={classNames('row', 'flex-column', 'pb-3', {
                                active:
                                  props.massStartTime &&
                                  props.massEndTime &&
                                  props.isMassSearchActive,
                              })}
                            >
                              <div className="label label-title font-weight-bold color-primary">
                                Mass Times:
                              </div>
                              
                              <div className="value align-items-center">
                              <span className="mr-0">Between</span>
                                <TimePicker
                                  onChange={props.onMassStartTimeChange}
                                  showSecond={false}
                                  use12Hours
                                  value={props.massStartTime}
                                  format={format}
                                  minuteStep={10}
                                  className="first"
                                />
                                <span> and </span>
                                <TimePicker
                                  onChange={props.onMassEndTimeChange}
                                  showSecond={false}
                                  use12Hours
                                  value={props.massEndTime}
                                  format={format}
                                  minuteStep={10}
                                  className="second"
                                />
                              </div>
                            </div>


                            {windowSize < 767 &&
                            <div
                            className={classNames('row', 'flex-column', 'pb-3', {
                              active:
                                props.confessionStartTime &&
                                props.confessionEndTime &&
                                !props.isMassSearchActive,
                            })}
                          >
                            <div className="label label-title font-weight-bold color-primary">
                              Confession Times:
                            </div>
                          
                            <div className="value align-items-center">
                            <span className="mr-0">Between</span>
                              <TimePicker
                                onChange={value => {
                                  props.setConfessionStartTime(value)
                                  props.checkIfValidSearch()
                                }}
                                showSecond={false}
                                use12Hours
                                className="first"
                                value={props.confessionStartTime}
                                format={format}
                                minuteStep={10}
                              />
                              <span> and </span>
                              <TimePicker
                                onChange={value => {
                                  props.setConfessionEndTime(value)
                                  props.checkIfValidSearch()
                                }}
                                className="second"
                                showSecond={false}
                                use12Hours
                                value={props.confessionEndTime}
                                format={format}
                                minuteStep={10}
                              />
                            </div>
                          </div>}




              </div>


              {/*  */}
              {/*  */}
              {/*  */}


              <div
                className={classNames('col-6', {
                  active: props.isConfessionDaysActive && !props.isMassSearchActive,
                })}
              >
                <div className="label label-title font-weight-bold color-primary">Confession: Day of the Week</div>
                <div className="radio-list">
                  {DaysOfTheWeek.map((day: IDay, index: number) => (
                    <Checkbox
                      key={day.name + ChurchEvents.Confession}
                      label={day.name}
                      value={props.confessionDaysSelected.includes(index)}
                      onClick={event => {
                        if (event)
                          props.toggleDayOfTheWeek(ChurchEvents.Confession, index)
                      }}
                      classes={'vertical-centered confession margin-mobile d-flex'}
                    />
                  ))}
                </div>

                      {windowSize > 767 &&
                      <div
                      className={classNames('row', 'flex-column', 'pb-3', {
                        active:
                          props.confessionStartTime &&
                          props.confessionEndTime &&
                          !props.isMassSearchActive,
                      })}
                    >
                      <div className="label label-title font-weight-bold color-primary">
                        Confession Times:
                      </div>
                    
                      <div className="value align-items-center">
                      <span className="ml-2 mr-0">Between</span>
                        <TimePicker
                          onChange={value => {
                            props.setConfessionStartTime(value)
                            props.checkIfValidSearch()
                          }}
                          showSecond={false}
                          use12Hours
                          className="first"
                          value={props.confessionStartTime}
                          format={format}
                          minuteStep={10}
                        />
                        <span> and </span>
                        <TimePicker
                          onChange={value => {
                            props.setConfessionEndTime(value)
                            props.checkIfValidSearch()
                          }}
                          className="second"
                          showSecond={false}
                          use12Hours
                          value={props.confessionEndTime}
                          format={format}
                          minuteStep={10}
                        />
                      </div>
                    </div>}
                        
              </div>
              {/*  */}
            </div>
            
        </div>
      </div>
      {/* {props.show && <Overlay onClick={props.close} />} */}
    </div>
  )
}

export default Filters
{/* <div
  className={classNames('row', 'range', 'align-items-center', { active: props.range > 0 })}
>
  <div className="value within-km-range">
    <div className="label-title font-weight-bold color-primary">Within:</div>{' '}
    <NumericInput
      strict={true}
      value={props.range}
      min={0}
      max={1000}
      onChange={props.onRangeChange}
    />{' '}
    km
  </div>
</div>*/}