import React, { useState, useEffect, SetStateAction } from 'react';

interface Props {
    massTitle: string,
    massContent: JSX.Element[],
    parishId: number,
  }

const MassTimeButton = (props: Props) => {
    const [displayTimes, setDisplayTimes] = useState(false);

    const toggleTimes = () => {
        setDisplayTimes(!displayTimes);
    }

    return (
        <>
            <button
                onClick={toggleTimes}
                className="button"
            >
                {props.massTitle}
            </button>
            {displayTimes === true && 
                <div
                    className={`times-container ${displayTimes}`}
                >
                    {props.massContent}
                </div>
            }
        </>
    );
}

export default MassTimeButton;