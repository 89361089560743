import React, { useState, useEffect, SetStateAction } from 'react';
// import { useLocation } from 'react-router';
import ParishAPI from '../api/Parish';
import { Parish } from './Types';
import MassTimeButton from './MassTimeButton';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faMapSigns,
    faSearch,
    faEnvelope,
    faGlobeAmericas,
    faPhone,
    faFax,
    faMapMarkerAlt,
  } from '@fortawesome/free-solid-svg-icons';
import Masonry from 'react-masonry-css';
import './MassTimeFinder.scss';

const MassTimeFinder = () => {
    const [parishes, setParishes] = useState<Parish[]>([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [searchType, setSearchType] = useState('All');
    const [displayedParishTimes, setDisplayedParishTimes] = useState<number[]>([]);

    let parishesList: JSX.Element[] = [];

    const masonryBreakpointCols = {
        default: 4,
        1600: 3,
        1300: 2,
        // 1024: 2,
        500: 1
      };

    const fetchParishes = async () => {
        try {
            let parishData = await ParishAPI.getAllMass();
            setParishes(parishData);
        } catch (error) {
            console.warn(error);
        }
    }

    /**
     * 
     */
     useEffect(() => {
        fetchParishes();
    }, []);

    //get list of parishes to be displayed (which ones are displayed depends on the user's search terms / tpyes)
    parishes.map((parish, index) => {
        let searchKeywords = searchTerm.toLowerCase();

        if(
            parish.location.city.toLowerCase().includes(searchKeywords) || parish.location.address.toLowerCase().includes(searchKeywords) ||
            parish.email.toLowerCase().includes(searchKeywords) || parish.phone_number.toLowerCase().includes(searchKeywords) ||
            parish.name.toLowerCase().includes(searchKeywords)
        ){
            if(parish.location.city === searchType || searchType === 'All'){
                let massTimes: JSX.Element[] = [];
                let googleLink = `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(parish.location.address)}`;
                let origin = `&origin=`;
                let destination = `&destination=${encodeURIComponent(parish.location.address)}`;
                let googleDirections = `https://www.google.com/maps/dir/?api=1${origin}${destination}`;

                if(parish.mass_times != null || !parish.mass_times){
                    try{
                        parish.mass_times.forEach(mass => {
                            massTimes.push(
                                    <div className="times-container-inner">
                                        <span>{mass.day}</span>
                                        <span>{mass.time}</span>
                                        <span>{mass.language}</span>
                                    </div>
                            );
                        });
                    }
                    catch(e){
                        // console.log((e as Error).message);
                    }
                }

                return parishesList.push(
                    <div
                        // className="searchResult col-10 col-md-6 col-xl-4"
                        className="searchResult"
                        key={index}
                        // onClick={() => onClick(parish)}
                        // style={{//padding: "15px"}}
                    >
                        <div className="inner-container">
                            <div className="title">
                                <h4>{`${parish.name} - ${parish.location.city}`}</h4>
                            </div>
                            <div className="content">
                                {googleLink &&
                                    <div className='content-inner'>
                                        <FontAwesomeIcon className="mr-3" icon={faMapMarkerAlt} />
                                        <p style={{marginBottom: '2em'}}>
                                            <a href={googleLink} target="_blank">{parish.location.address}</a>
                                        </p>
                                    </div>
                                }
                                <div>
                                    {parish.fax_number &&
                                        <div className='content-inner'>
                                            <FontAwesomeIcon icon={faFax} />
                                            <p style={{marginBottom: '2em'}}>
                                            {parish.fax_number}
                                            </p>
                                        </div>
                                    }
                                    
                                    {parish.phone_number && 
                                        <div className='content-inner'>
                                            <FontAwesomeIcon icon={faPhone} />
                                            <p style={{marginBottom: '2em'}}>
                                            {parish.phone_number}
                                            </p>
                                        </div>
                                    }
                                    
                                    {parish.email &&
                                        <div className='content-inner'>
                                            <FontAwesomeIcon icon={faEnvelope} />
                                            <p>
                                                <a href={parish.email}>{parish.email}</a>
                                            </p>
                                        </div>
                                    }
                                    
                                    {parish.website &&
                                        <div className='content-inner'>
                                            <FontAwesomeIcon icon={faGlobeAmericas} />
                                            <p style={{marginBottom: '2em'}}>
                                            <a href={parish.website} target="_blank">{parish.website}</a>
                                            </p>
                                        </div>
                                    }
                                </div>
                                <div 
                                    className="row align-items-center"
                                    style={
                                        {
                                            // padding: '10px 0 25px 0',
                                            margin: '0px'
                                        }
                                    }>
                                    <a
                                        href={googleDirections}
                                        target="_blank"
                                        className="button__default-secondary__grey btn-style-1 my-2 p-3"
                                        >
                                            <FontAwesomeIcon className="mr-3" icon={faMapMarkerAlt} />
                                            Directions
                                    </a>

                                    {massTimes.length > 0 && 
                                        <MassTimeButton
                                            massTitle="Mass Times"
                                            massContent={massTimes}
                                            parishId={parish.id}
                                            // toggleTimes={toggleMassTimes}
                                        />
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            }
        }
    })
    
    const clickedSearchType = (type: string) => {
        setSearchType(type);
    };

    return (
        <div>
            <div style={{marginBottom: '30px'}}>
                <div className="search-field-container" style={{display: 'inline-block'}}>
                    <input
                        // onFocus={() => {
                        //   setShowResults(false)
                        // }}
                        style={{margin: '0px 5px 20px 0px'}}
                        className="search-field"
                        type="text"
                        placeholder="Search Mass Times..."
                        value={searchTerm}
                        onChange={event => {
                        setSearchTerm(event.target.value)
                        }}
                    />
                    <FontAwesomeIcon icon={faSearch} />
                </div>
                
                <button
                    className={searchType === 'Edmonton' ? 'active search-btn' : 'search-btn'}
                    value="Edmonton"
                    onClick={ (e) => clickedSearchType( (e.target as HTMLInputElement).value ) }
                >
                    Edmonton Only
                </button>
                <button
                    className={searchType === 'All' ? 'active search-btn' : 'search-btn'}
                    value="All"
                    onClick={ (e) => clickedSearchType( (e.target as HTMLInputElement).value ) }
                >
                    All Parishes
                </button>
            </div>

            <div 
                // className="row justify-content-center justify-content-md-between w-100"
                className="">
                    <Masonry
                        breakpointCols={masonryBreakpointCols}
                        columnClassName="masonry-grid_column"
                        className='masonry-grid'
                    >
                        {parishesList}
                    </Masonry>
                
            </div>
        </div>
        
    );
}

export default MassTimeFinder;