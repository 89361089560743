import React from 'react'
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import { EventCalendar } from './event-calendar/EventCalendar'
import ParishMap from './parish-map/ParishMap'
import Landing from './landing/landing'
import PriestSearch from './priest-search/PriestSearch'
import MassTimeFinder from './mass-time-finder/MassTimeFinder';

export default function App() {

  const searchKeywords = (text: string) => {
    const keywordListInitial = ['st ', 'saint', 'reverend', 'Fr.', 'Fr ', 'Father', 'Pastor', 'Priest', 'most reverend', 'doctor', 'dr ', 'sr ', 'sister', 'br ', 'brother', 'dcn ', 'dcn.'];
    const keywordListProper = ['St. ', 'St.', 'Rev.', 'Rev.', 'Rev. ', 'Rev.', 'Rev.', 'Rev.', 'Most Rev.', 'Dr.', 'Dr. ', 'Sr. ', 'Sr.', 'Br. ', 'Br.', 'Deacon ', 'Deacon'];

    let updatedSearchTerm = text.toLocaleLowerCase();
      
      //check if the search term starts with a specific keyword (ie: 'st '); each parish or person (if they have a title) has a period after the title; ie: St. Vital, Mrs. XYZ, etc.
      //if someone searches for 'st vital' they get no results; the below foreach looks for these titles (without periods), and modifies it to contain a period,
      //thus making the search a bit more flexible
      keywordListInitial.forEach( function(element: string, index) {
        let elemLower = element.toLocaleLowerCase();
        if( updatedSearchTerm.startsWith(elemLower) ){
          updatedSearchTerm = updatedSearchTerm.replace(elemLower, keywordListProper[index]);
          // console.log(`keywordListProper[index]=${keywordListProper[index]}`);
          // console.log(`updatedSearchTerm=${updatedSearchTerm}`);
        }
      });

      return updatedSearchTerm;
  }

  return (
    <Router>
      <div>
        <Switch>
          <Route exact path="/">
            <Landing searchKeywords={searchKeywords} />
          </Route>
          <Route path="/parishes">
            <ParishMap
              searchKeywords={searchKeywords}
             />
          </Route>
          <Route path="/mass-time-finder">
            <MassTimeFinder
             />
          </Route>
          <Route path="/events">
            <EventCalendar />
          </Route>
          <Route path="/about/priests">
            <PriestSearch />
          </Route>
        </Switch>
      </div>
    </Router>
  )
}
