import React, { useEffect, MouseEventHandler } from 'react'
import { Priest, SelectedFilters } from './Types'
import { Parish } from '../parish-map/Types'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons'
import PriestRow from './PriestRow'

interface Props {
  priests: Priest[],
  selectedFilters: SelectedFilters,
  searchTerm: string
}


const sortOptions = {
  name: 'Name',
  location: 'Location',
  parish: 'Parish',
  language: 'Language',
  asc: 'ASC',
  desc: 'DESC'
}

const Results = (props: Props) => {
  const sortPriests = (): Priest[] => {
    const sortedPriests = props.priests.sort((a: Priest, b: Priest) => {
      if (sortBy === sortOptions.name) {
        if (sortOrder === sortOptions.asc && a.name > b.name) return 1
        if (sortOrder === sortOptions.asc && a.name < b.name) return -1
        if (sortOrder === sortOptions.desc && a.name > b.name) return -1
        if (sortOrder === sortOptions.desc && a.name < b.name) return 1
        return 0
      } else if (sortBy === sortOptions.location) {
        let locationA = '';
        let locationB = '';
        
        if(a.locations){
          locationA = a.locations[0];
        }
        if(b.locations){
          locationB = b.locations[0];
        }

        if (sortOrder === sortOptions.asc && locationA > locationB) return 1
        if (sortOrder === sortOptions.asc && locationA < locationB) return -1
        if (sortOrder === sortOptions.desc && locationA > locationB) return -1
        if (sortOrder === sortOptions.desc && locationA < locationB) return 1
        else return 0
      } else if (sortBy === sortOptions.parish) {
        let parishA = '';
        let parishB = '';

        if(a.parishes){
          parishA = a.parishes[0].name;
        }
        if(b.parishes){
          parishB = b.parishes[0].name;
        }

        if (sortOrder === sortOptions.asc && parishA > parishB) return 1
        if (sortOrder === sortOptions.asc && parishA < parishB) return -1
        if (sortOrder === sortOptions.desc && parishA > parishB) return -1
        if (sortOrder === sortOptions.desc && parishA < parishB) return 1
        else return 0
      } else if (sortBy === sortOptions.language) {
        if (sortOrder === sortOptions.asc && a.languages > b.languages) return 1
        if (sortOrder === sortOptions.asc && a.languages < b.languages) return -1
        if (sortOrder === sortOptions.desc && a.languages > b.languages) return -1
        if (sortOrder === sortOptions.desc && a.languages < b.languages) return 1
        else return 0
      }
      else return 0;
    })
    return sortedPriests
  }

  const [sortBy, setSortBy] = useState<string>('Name')
  const [sortOrder, setSortOrder] = useState<string>('ASC')
  const [sortedPriests, setSortedPriests] = useState<Priest[]>(sortPriests())

  useEffect(() => {
    setSortedPriests(sortPriests())
  }, [sortBy, sortOrder])

  const filterByLanguage = (languages: string[]) => {
    if ((props.selectedFilters.language && languages.some(language => props.selectedFilters.language === language)) ||
      !props.selectedFilters.language) { return true }
  }
  const filterByLocation = (locations: string[]) => {
    let priestLocation = '';

    if(locations){
      priestLocation = locations[0];
    }

    if ((props.selectedFilters.location && props.selectedFilters.location === priestLocation/*locations.some(location => props.selectedFilters.location === location)*/) ||
      !props.selectedFilters.location) { return true }
  }
  const filterByParish = (parishes: Parish[]) => {
    let priestParish = '';

    if(parishes){
      priestParish = parishes[0].name;
    }

    if ((props.selectedFilters.parish && props.selectedFilters.parish === priestParish/*parishes.some(parish => props.selectedFilters.parish === parish.name)*/) ||
      !props.selectedFilters.parish) { return true }
  }

  const sortByCategory = (category: string) => {
    if (category === sortBy) {
      const newOrder = sortOrder === sortOptions.asc ? sortOptions.desc : sortOptions.asc
      setSortOrder(newOrder)
    } else {
      setSortOrder(sortOptions.asc)
      setSortBy(category)

    }
  }

  const getParishNames = (parishes: Parish[]) => {
    const parishNames: string[] = []
    parishes.forEach(parish => parishNames.push(parish.name))
    return parishNames.join(', ')
  }

  const sortIcon = (): JSX.Element => {
    return sortOrder === sortOptions.asc ?
      <FontAwesomeIcon size="lg" transform={{ y: -2, x: 2 }} icon={faSortDown} /> :
      <FontAwesomeIcon size="lg" transform={{ y: 2, x: 2 }} icon={faSortUp} />
  }
  let noMatches = true;

  return <div className="col-12">
    <table className="alt-grey-background">
      <thead>
        <tr>
          <th
            onClick={() => { sortByCategory(sortOptions.name) }}>
            Name
            {sortBy === sortOptions.name && sortIcon()}
          </th>
          <th
            onClick={() => { sortByCategory(sortOptions.location) }}>
            Location
            {sortBy === sortOptions.location && sortIcon()}
          </th>
          {/* <th
            onClick={() => { sortByCategory(sortOptions.language) }}>
            Languages
            {sortBy === sortOptions.language && sortIcon()}
          </th> */}
          <th
            onClick={() => { sortByCategory(sortOptions.parish) }}>
            Parishes
            {sortBy === sortOptions.parish && sortIcon()}
          </th>
        </tr>
      </thead>
      <tbody>
        {sortedPriests.map((priest, index) => {
          if (filterByLanguage(priest.languages) && filterByLocation(priest.locations) && filterByParish(priest.parishes)) {
            noMatches = false
            return <PriestRow key={index} searchTerm={props.searchTerm} priest={priest} getParishNames={getParishNames} />

          }

        })}

      </tbody>
    </table>
    {noMatches && <p style={{ textAlign: "center" }}>No priests match those filters / terms</p>}
  </div>
}

export default Results
