import React, { useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'

import { Parish, SearchResult } from './Types'

var classNames = require('classnames')

interface Props {
  parish: Parish
  clicked: boolean
  parishClicked: (searchResult: SearchResult) => void,
  onEntryClicked: (parish: Parish) => void
  hovered: boolean
  onEntryHovered: (parish: Parish) => void
  onEntryHoverExit: () => void
  label: string
}

const ListEntry = (props: Props) => {
  const ref = React.createRef<HTMLDivElement>()

// console.log(`------------------`);
//   console.log(`clicked=${props.clicked}`);
//   console.log(props.parish);
//   console.log(`onEntryClicked=${props.onEntryClicked}`);
//   console.log(`------------------`);

  //update the website's uri address (the parishid portion) with the currently clicked parish from the parish map list
  const updateUri = () => {
    let clickedChurch = {
      type: 'parish',
      parish: props.parish
    };

    props.parishClicked(clickedChurch)
  };

  useEffect(() => {
    if (props.clicked) {
      if (ref.current) {
        ref.current.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        })
      }
    }
  })

  return (
    <div
      ref={ref}
      className={classNames({
        listItem: true,
        selected: props.hovered || props.clicked,
      })}
      onClick={() => {props.onEntryClicked(props.parish); updateUri();}}
      onMouseEnter={() => props.onEntryHovered(props.parish)}
      onMouseLeave={() => props.onEntryHoverExit()}
    > 
      
      <div>{props.parish.name}</div>
      
      <div className='parish-finder'>
        <span className='finder-label'>{props.label}</span>
        {props.parish.distance && (
          <div className='distance'>
            {(props.parish.distance / 1000.0).toFixed(2)}km away
          </div>
        )}
      </div>

      <button
        className={classNames({
          info: true,
          hidden: !props.hovered && !props.clicked,
        })}
      >
        {window.innerWidth > 1024 && (
          <FontAwesomeIcon className='ml-1' icon={faArrowRight} />
        )}
      </button>
    </div>
  )
}

export default ListEntry
