import React, { useState } from 'react'
import Dropdown from '../components/Dropdown'
import Search from './Search'
import { faMapMarked, faComment, faChurch } from '@fortawesome/free-solid-svg-icons'
import { SelectedFilters } from './Types'
import './filters.scss'


export interface DropdownOption {
  language?: string,
  location?: string,
  parish?: string,
}

interface Props {
  locations: string[];
  languages: string[];
  parishes: string[];
  setFilters: (optionsChanged: DropdownOption[]) => void,
  setSearchTerm: (term: string) => void
  searchTerm: string,
  selectedFilters: SelectedFilters
}

const Filters = (props: Props) => {
  const initialState = {
    showSearch: false,
    openCategory: ''
  }

  const STRINGS = {
    location: "Location",
    language: "Language",
    parish: "Parish"
  }

  const [showSearch, setShowSearch] = useState(initialState.showSearch)
  const [openCategory, setOpenCategory] = useState(initialState.openCategory)

  const onSelect = (dropdown: string, option: string) => {
    switch (dropdown) {
      case STRINGS.language:
        props.setFilters([{ language: option }])
        break;
      case STRINGS.location:
        props.setFilters([{ location: option }])
        break;
      case STRINGS.parish:
        props.setFilters([{ parish: option }])
        break;
    }
  }

  const clearFilters = () => {
    props.setFilters([{ language: '' }, { location: '' }, { parish: '' }])
    setOpenCategory('')
  }

  const hideSearch = () => {
    setShowSearch(false)
  }

  const onOpenCategoryDropdown = (category: string) => {
    setOpenCategory(category)
  }

  return <div id="priests-filters" className="d-flex flex-column flex-xl-row flex-1 align-items-center col-12 flex-wrap height-100">
    <div className="d-flex flex-column flex-xl-row">
      <Dropdown
        category={STRINGS.location}
        icon={faMapMarked}
        options={props.locations}
        select={onSelect}
        selectedOption={props.selectedFilters.location}
        show={!showSearch}
        open={openCategory === STRINGS.location}
        onClick={onOpenCategoryDropdown}
      />
      {/* <Dropdown
        category={STRINGS.language}
        icon={faComment}
        options={props.languages}
        select={onSelect}
        selectedOption={props.selectedFilters.language}
        show={!showSearch}
        open={openCategory === STRINGS.language}
        onClick={onOpenCategoryDropdown}
      /> */}
      <Dropdown
        category={STRINGS.parish}
        icon={faChurch}
        options={props.parishes}
        select={onSelect}
        selectedOption={props.selectedFilters.parish}
        show={!showSearch}
        open={openCategory === STRINGS.parish}
        onClick={onOpenCategoryDropdown}
      />

    </div>
    <div className="d-flex justify-content-around flex-1 height-100 align-items-center">
      <div className={showSearch ? 'width-percent-100' : 'button__default-primary'} onClick={() => !showSearch && setShowSearch(true)}>
        <Search searchTerm={props.searchTerm} show={showSearch} hideSearch={hideSearch} setSearchTerm={props.setSearchTerm} />
      </div>
      {!showSearch &&
        <div className="button__default-secondary" onClick={() => clearFilters()}>Clear</div>
      }

    </div>
  </div>
}

export default Filters;
