import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { IconProp } from '@fortawesome/fontawesome-svg-core'


interface DropdownProps {
  category: string;
  icon: IconProp;
  options: string[];
  select: (dropdown: string, option: string) => void
  selectedOption: string,
  show?: boolean
  open: boolean
  onClick: (category: string) => void
}

const Dropdown = (props: DropdownProps) => {
  return (
    <div className={`d-flex flex-column ${props.show ? '' : 'hide-filter'}`}>
      <ul onClick={() => { props.onClick(props.category) }} className="select m-5">
        <li>
          <input className="select_close" type="radio" name={props.category} id={`${props.category}-close`} value='' />
          <span className="select_label select_label-placeholder">
            <FontAwesomeIcon icon={props.icon} className="mr-3" />
            {props.category}</span>
        </li>
        {/* zIndex makes sure that other dropdowns behave consistently when there are multiple dropdowns */}
        <li className="select_items" style={{ zIndex: props.open ? 1001 : 1000 }}>
          <input className="select_expand" type="radio" name={props.category} id={`${props.category}-opener`} checked={props.open} />
          <label className="select_closeLabel" htmlFor={`${props.category}-close`}></label>

          <ul className="select_options">
            {props.options && props.options.map((option, index) => {
              return (
                <li key={index} className="select_option" onClick={(e) => props.select(props.category, option)}>
                  <input className="select_input" type="radio" name={props.category} id={option} checked={props.selectedOption === option} />
                  <label className="select_label" htmlFor={option}>{option}</label>
                </li>
              )

            })}
          </ul>

          <label className="select_expandLabel" htmlFor={`${props.category}-opener`}></label>
        </li>
      </ul>
    </div>
  )
}

export default Dropdown
