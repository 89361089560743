
import React from 'react';
import { geolocated, GeolocatedProps } from "react-geolocated";

class Basic extends React.Component<GeolocatedProps> {
  render() {
    if(this.props.positionError) {
      return (
        <div>Error getting current location.</div>
      )
    }

    return null
  }
}

export default geolocated({
  positionOptions: {
      enableHighAccuracy: false,
  },
  userDecisionTimeout: 5000,
})(Basic);
