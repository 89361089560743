import { WP_REST_URL_PRIESTS } from '../constants/constants'


export default class Priests {
  static fetchPriests = async () => {
    return (await fetch(
      WP_REST_URL_PRIESTS, {
      method: 'GET'
    }
    )).json();
  }
}
