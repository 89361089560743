import React, { useState, useEffect } from 'react'
import Filters, { DropdownOption } from './Filters'
import Results from './Results'
import { Priest, SelectedFilters } from '../priest-search/Types'
import PriestAPI from '../api/Priest'
import { sort } from '../utils/sort'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

const PriestSearch = () => {
  const initialState = {
    priests: [],
    locations: [],
    languages: [],
    parishes: [],
    selectedFilters: { location: '', language: '', parish: '' },
    searchTerm: ''
  }
  const [priests, setPriests] = useState<Priest[]>(initialState.priests)
  const [locations, setLocations] = useState<string[]>(initialState.locations)
  const [languages, setLanguages] = useState<string[]>(initialState.languages)
  const [parishes, setParishes] = useState<string[]>(initialState.parishes)
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>(initialState.selectedFilters)
  const [searchTerm, setSearchTerm] = useState<string>(initialState.searchTerm)

  // Grab priests only on initial render
  useEffect(() => {
    const fetchPriests = async () => await PriestAPI.fetchPriests()
    fetchPriests().then(priestsReturned => {
      setPriests(priestsReturned)
    });
  }, [])



  const getAllLocations = (priests: Priest[]) => {
    const locationsFound: string[] = [];
    if (priests.length > 0) {
      priests.forEach(priest => {
        if(priest.locations){
          if (!locationsFound.includes(priest.locations[0])){
            locationsFound.push(priest.locations[0])
          }
        }
        
        // priest.locations.forEach(location => {
        //   if (!locationsFound.includes(location)) locationsFound.push(location)
        // })
      })
    }
    sort(locationsFound)
    setLocations(locationsFound)
  }

  const getAllLanguages = (priests: Priest[]) => {
    const languagesFound: string[] = [];
    if (priests.length > 0) {
      priests.forEach(priest => {
        if(priest.languages){
          priest.languages.forEach(location => {
            if (!languagesFound.includes(location)) languagesFound.push(location)
          })
        }
      })
    }
    sort(languagesFound)
    setLanguages(languagesFound)
  }

  const getAllParishes = (priests: Priest[]) => {
    const parishesFound: string[] = [];
    if (priests.length > 0) {
      priests.forEach(priest => {
        if(priest.parishes){
          priest.parishes.forEach(parish => {
            if (!parishesFound.includes(parish.name)) parishesFound.push(parish.name)
          })
        }
      })
    }
    sort(parishesFound)
    setParishes(parishesFound)
  }

  useEffect(() => {
    getAllLocations(priests)
    getAllLanguages(priests)
    getAllParishes(priests)
  }, [priests])

  const setFilters = (optionChanged: DropdownOption[]) => {
    const newSelectedFilters = Object.assign([], selectedFilters)
    optionChanged.forEach(option => {
      if ('language' in option && option.language !== undefined) {
        newSelectedFilters.language = option.language
      }
      if ('location' in option && option.location !== undefined) {
        newSelectedFilters.location = option.location
      }
      if ('parish' in option && option.parish !== undefined) {
        newSelectedFilters.parish = option.parish
      }
    })
    setSelectedFilters({ ...newSelectedFilters })
  }

  const setSearch = (searchTerm: string) => {
    setSearchTerm(searchTerm)
  }

  return (
    <div className="d-flex flex-column align-items-center col-12 col-md-10 offset-md-1">
      <Filters
        locations={locations}
        languages={languages}
        parishes={parishes}
        setFilters={setFilters}
        setSearchTerm={setSearch}
        searchTerm={searchTerm}
        selectedFilters={selectedFilters}
      />
      {!priests.length &&
        <FontAwesomeIcon icon={faSpinner} spin={true} />}
      {priests.length > 0 &&
        <Results searchTerm={searchTerm} priests={priests} selectedFilters={selectedFilters} />}
    </div>
  )
}

export default PriestSearch
