export interface IDay {
  value: number;
  name: string;
  abbr: string;
}

export const DaysOfTheWeek: IDay[] = [
  {
    value: 0,
    name: 'Sunday',
    abbr: 'Sun',
  },
  {
    value: 1,
    name: 'Monday',
    abbr: 'M',
  },
  {
    value: 2,
    name: 'Tuesday',
    abbr: 'Tu',
  },
  {
    value: 3,
    name: 'Wednesday',
    abbr: 'W',
  },
  {
    value: 4,
    name: 'Thursday',
    abbr: 'Th',
  },
  {
    value: 5,
    name: 'Friday',
    abbr: 'F',
  },
  {
    value: 6,
    name: 'Saturday',
    abbr: 'Sat',
  },
];
