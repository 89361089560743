import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch, faSpinner, faTimes } from '@fortawesome/free-solid-svg-icons'
import AwesomeDebouncePromise from 'awesome-debounce-promise'
import { useAsync } from 'react-async-hook'
import useConstant from 'use-constant'

import ParishAPI from '../api/Parish'
import { SearchResult } from '../parish-map/Types'

interface Props {
  children: React.ReactNode
  searchResultClicked: (parishId: number, personId?: number) => void,
  searchKeywords: (text: string) => string
}

const Search = (props: Props) => {
  const [showResults, setShowResults] = useState(false);
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [searchResults, setSearchResults] = useState<SearchResult[]>([]);
  const [searching, setSearching] = useState<boolean>(false);

  // const keywordListInitial = ['st ', 'saint ', 'Rev ', 'reverend ', 'most rev '];
  // const keywordListInitial = ['st ', 'saint ', 'reverend ', 'most reverend ', 'doctor '];
  // const keywordListProper = ['St. ', 'St. ', 'Rev. ', 'Most Rev. ', 'Dr .'];

  const search = async (text: string) => {
    try {
      if (text.length === 0) {
        setShowResults(false)
        return
      }

      // let updatedSearchTerm = text.toLocaleLowerCase();
      
      //check if the search term starts with a specific keyword (ie: 'st '); each parish or person (if they have a title) has a period after the title; ie: St. Vital, Mrs. XYZ, etc.
      //if someone searches for 'st vital' they get no results; the below foreach looks for these titles (without periods), and modifies it to contain a period,
      //thus making the search a bit more flexible
      // keywordListInitial.forEach( function(element, index) {
      //   let elemLower = element.toLocaleLowerCase();
      //   if( updatedSearchTerm.startsWith(elemLower) ){
      //       updatedSearchTerm = updatedSearchTerm.replace(elemLower, keywordListProper[index]);
      //   }
      // });

      let updatedSearchTerm = props.searchKeywords(text);
      
      // ***********

      setSearching(true)
      let searchResults = await ParishAPI.search(updatedSearchTerm)
      setSearchResults(searchResults)
      setShowResults(true)
      setSearching(false)
    } catch (error) {
      console.warn(error)
    }
  }


  // const onClick = (parishId: number, personName?: string) => {
  //   setShowResults(false)
  //   setSearchResults([])
  //   props.searchResultClicked(parishId)
  // }

  const onClick = (searchResult: SearchResult) => {
    setShowResults(false)    
    setSearchResults([])
    props.searchResultClicked(searchResult.parish.id, (searchResult.person ? searchResult.person.ID : undefined))
  }

  // check back to see if awesome-debounce, use-constant and useAsync have built-in
  // react equivalents later on.
  const searchAPIDebounced = useConstant(() =>
    AwesomeDebouncePromise(search, 1000)
  )

  useAsync(
    async (text: string) => {
      return searchAPIDebounced(text)
    },
    [searchTerm]
  )

  const clearSearch = () => {
    setSearchTerm('')
    setSearchResults([])
    setShowResults(false)
  }

  // render results
  let searchResultsList: JSX.Element[] = []
  if (showResults && searchResults.length === 0) {
    searchResultsList.push(
      <div key={0} className="searchResult">
        No Results
      </div>
    )
  }
  
  searchResults.map((searchResult, index) => {

    return searchResultsList.push(
      <div
        className="searchResult"
        key={index}
        onClick={() => onClick(searchResult)}
      >
        <div className="resultIcon">
          {searchResult.type === 'parish' &&(
            <i className="fas fa-church"></i>
          )}

           {searchResult.type !== 'parish' &&(
            <i className="fas fa-user"></i>
          )}
        </div>
        <div>
          {searchResult.person && (
            <div className="person">{searchResult.person.name}</div>
          )}
          <div className="parish">{searchResult.parish.name}</div>
        </div>
      </div>
    )
  })

  return (
    <div className="searchContainer">

        <div className="mb-3">
          <input
            onFocus={() => {
              setShowResults(false)
            }}
            className='location-search-input'
            type="text"
            placeholder="Search by Person or Place..."
            value={searchTerm}
            onChange={event => {
              setSearchTerm(event.target.value)
            }}
          />
          
          {searching === true && (
            <i className="fas fa-spinner"></i>
          )}

          <button className="clear search-clear" onClick={clearSearch}>
            <FontAwesomeIcon icon={faTimes} />
          </button>
        </div>
        {props.children}

      <div className="autocomplete-dropdown-container">
        {searchResultsList}
      </div>
    </div>
  )
}

export default Search
