import React from 'react'
import { Parish } from '../parish-map/Types'
import { Priest } from './Types'

interface Props {
  getParishNames: (parishes: Parish[]) => string
  priest: Priest,
  searchTerm: string
}

const PriestRow = (props: Props) => {
  const { priest, getParishNames, searchTerm } = props

  const priestIsMatch = () => {
    let location = '';
    let languages = '';
    let parishName = '';
    
    if(priest.locations) location = priest.locations[0].toLowerCase();
    // if(priest.languages.length > 0) languages = priest.languages[0].toLowerCase();
    if(priest.parishes) parishName = priest.parishes[0].name.toLowerCase();

    if (!!!searchTerm ||
      priest.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      location.includes(searchTerm.toLowerCase())/*priest.locations[0].toLowerCase().includes(searchTerm.toLowerCase())/*some(location => location.toLowerCase().includes(searchTerm.toLowerCase()))*/ ||
      // languages.includes(searchTerm.toLowerCase())/*priest.languages[0].toLowerCase().includes(searchTerm.toLowerCase())/*.some(language => language.toLowerCase().includes(searchTerm.toLowerCase()))*/ ||
      parishName.includes(searchTerm.toLowerCase())/*priest.parishes[0].name.toLowerCase().includes(searchTerm.toLowerCase())/*some(parish => parish.name.toLowerCase().includes(searchTerm.toLowerCase()))*/) return true
  }

  let location = '';
  let parish = '';

  if(priest.locations){
    location = priest.locations[0];
  }

  if (priestIsMatch()) {
    let priestLinkRow = <td className="p-3">{priest.name}</td>;
    let parishName = '';

    if(priest.parishes){
      parishName = priest.parishes[0].name;
    }
    let parishLinkRow = <td className="p-3">{parishName}</td>;

    if(priest.parishes){
      let link = `/parishes?parishid=${priest.parishes[0].id}&personid=${priest.id}`;
      priestLinkRow = <td className="p-3"><a href={link}>{priest.name}</a></td>;
      parishLinkRow =  <td className="p-3"><a href={link}>{priest.parishes[0].name}</a></td>;
    }
    

    return <tr key={`${priest.name}${priest.locations}`}>
      {priestLinkRow}
      <td>{priest.locations &&
      priest.locations[0]}</td>
      {/* <td>priest.languages.join(', ')</td> */}
      {parishLinkRow}
    </tr>

  } else {
    return null
  }
}

export default PriestRow
