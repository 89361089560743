import React, { useState, useEffect, SetStateAction } from 'react'
import moment, { Moment } from 'moment'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChurch, faFilter } from '@fortawesome/free-solid-svg-icons'

import { Parish, Location, Time, SearchResult } from './Types'
import MyMapComponent from './Map'
import ListEntry from './ListEntry'
import Menu from './Menu'
import Filters from './Filters'
import Geolocated from './Geolocated'
import './ParishMap.scss'
import ParishAPI from '../api/Parish'
import {
  EdmontonCoordinates,
  LocationOptions,
} from '../constants/LocationOptions'
import { ChurchEvents } from '../constants/ChurchEvents'
import { ExtraPrograms } from '../constants/ExtraPrograms'
import { useIsInitialRender } from '../utils/useIsInitialRender'
import { getBoundsZoomLevel } from '../utils/getBoundsZoomLevel'
import Search from './Search'
import { MassTypes } from '../constants/MassTypes'
import { useLocation } from 'react-router'

import { Marker } from 'react-google-maps'

const initialState = {
  parishes: [],
  displayedParishes: [],
  clicked: -1,
  hovered: -1,
  centered: -1,
  showMenu: false,
  hasMenuAnimated: false,
  showFilters: false,
  hasFiltersAnimated: false,
  userLocation: undefined,
  byAddressLocation: undefined,
  isByAddressSearch: true,
  mapBounds: undefined,
  mapCenter: undefined,
  mapZoom: 6,
  massDaysSelected: [],
  isMassSearchActive: true,
  isDateRadioActive: true,
  confessionDaysSelected: [],
  dateRadio: undefined,
  isConfessionDaysActive: false,
  massStartTime: undefined,
  massEndTime: undefined,
  confessionStartTime: undefined,
  confessionEndTime: undefined,
  findByLocation: LocationOptions.currentLocation,
  address: '',
  range: 1000,
  childrensLiturgy: false,
  yaLiturgy: false,
  marriagePrep: false,
  rcia: false,
  isValidSearch: true,
  locationForDirections: undefined,
  searchedPerson: -1,
  // mapCircle: undefined,
  boundsParishes: [],
  dispParishesListAll: true,
  isSearchedRecord: false,
  massDateToday: false,
  massDateTomorrow: false,
}


function useQuery() {
  return new URLSearchParams(useLocation().search)
}

interface Props {
  // searchResultClicked: (parishId: number, personId?: number) => void
  searchKeywords: (text: string) => string
}

const ParishMap = (props: Props) => { 
  let parishList: JSX.Element[] = [];

  let query = useQuery()

  let initialAddress: Location = { address: '', lat: EdmontonCoordinates.lat, lng: EdmontonCoordinates.lng }
  let parishId = query.get('parishid')
  let personId = query.get('personid')
  let lat = query.get('lat')
  let lng = query.get('lng')

  if ((lat && lng ) || parishId === null) {
    initialAddress = {
      lat: EdmontonCoordinates.lat,
      lng: EdmontonCoordinates.lng,
      address: query.get('address') || ''
      //lat: parseFloat(lat),
      //lng: parseFloat(lng), 
      //address: query.get('address') || '',
    }
    //initialState.showMenu = true;
    //initialState.hasMenuAnimated = true;
    initialState.mapZoom = 6;
    initialState.isSearchedRecord = true;
  } else if (parishId !== null) {
    initialState.clicked = parseInt(parishId);
    initialState.centered = parseInt(parishId);
    initialState.showMenu = true;
    initialState.hasMenuAnimated = true;
    initialState.mapZoom = 18;
    initialState.isSearchedRecord = true;
  }

  if(personId !== null){
    initialState.searchedPerson = parseInt(personId);
  }

  const [searchedPerson, setsearchedPerson] = useState(initialState.searchedPerson);
  const [initialParishInstructions, setParishInstructions] = useState([]);
  const [parishPage, setParishPage] = useState([]);
  const [parishes, setParishes] = useState<Parish[]>(initialState.parishes)
  const [displayedParishes, setDisplayedParishes] = useState<Parish[]>(
    initialState.displayedParishes
  )

  const [dispParishesListAll, setDispParishesListAll] = useState<boolean>(initialState.dispParishesListAll)

  const [mapBoundsParishes, setMapBoundsParishes] = useState<Parish[]>(initialState.boundsParishes)

  const [clicked, setClicked] = useState<number>(initialState.clicked)
  const [hovered, setHovered] = useState<number>(initialState.hovered)
  const [centered, setCentered] = useState<number>(initialState.centered)

  const [showMenu, setShowMenu] = useState(initialState.showMenu)

  const [hasMenuAnimated, setHasMenuAnimated] = useState(
    initialState.hasMenuAnimated
  )
  const [showFilters, setShowFilters] = useState(initialState.showFilters)
  const [hasFiltersAnimated, setHasFiltersAnimated] = useState(
    initialState.hasFiltersAnimated
  )
  const [userLocation, setUserLocation] = useState<Location | undefined>(
    initialState.userLocation
  )
  const [byAddressLocation, setByAddressLocation] = useState<
    Location | undefined
  >(lat && lng ? initialAddress : initialState.byAddressLocation)

  const [isByAddressSearch, setIsByAddressSearch] = useState(
    lat && lng ? true : initialState.isByAddressSearch
  )

  const [locationForDirections, setLocationForDirections] = useState<
    Location | undefined
  >()

  const [mapBounds, setMapBounds] = useState<
    google.maps.LatLngBounds | undefined
  >(initialState.mapBounds)

  const [mapCenter, setMapCenter] = useState<google.maps.LatLng | undefined>(
    lat && lng
      ? new google.maps.LatLng(initialAddress.lat, initialAddress.lng)
      : parishId !== undefined
        ? undefined
        : initialState.mapCenter
  )
  const [mapZoom, setMapZoom] = useState<number>(initialState.mapZoom)

  const [massDaysSelected, setMassDaysSelected] = useState<number[]>(
    initialState.massDaysSelected
  )
  const [confessionDaysSelected, setConfessionDaysSelected] = useState<
    number[]
  >(initialState.confessionDaysSelected)
  const [dateRadio, setDateRadio] = useState<string | undefined>(
    initialState.dateRadio
  )

  const [isDateRadioActive, setIsDateRadioActive] = useState(
    initialState.isDateRadioActive
  )

  const [isConfessionDaysActive, setIsConfessionDaysActive] = useState(
    initialState.isConfessionDaysActive
  )
  const [massStartTime, setMassStartTime] = useState<Moment | undefined>(
    initialState.massStartTime
  )
  const [massEndTime, setMassEndTime] = useState<Moment | undefined>(
    initialState.massEndTime
  )
  const [confessionStartTime, setConfessionStartTime] = useState<
    Moment | undefined
  >(initialState.confessionStartTime)
  const [confessionEndTime, setConfessionEndTime] = useState<
    Moment | undefined
  >(initialState.confessionEndTime)
  const [findByLocation, setFindByLocation] = useState(
    lat && lng ? LocationOptions.byAddress : initialState.findByLocation
  )
  const [isMassSearchActive, setIsMassSearchActive] = useState(
    initialState.isMassSearchActive
  )
  const [address, setAddress] = useState(
    lat && lng ? initialAddress.address : initialState.address
  )
  const [range, setRange] = useState(initialState.range)
  const [childrensLiturgy, setChildrensLiturgy] = useState(
    initialState.childrensLiturgy
  )
  const [yaLiturgy, setYaLiturgy] = useState(initialState.yaLiturgy)
  const [marriagePrep, setMarriagePrep] = useState(initialState.marriagePrep)
  const [rcia, setRcia] = useState(initialState.rcia)
  const [isValidSearch, setIsValidSearch] = useState(initialState.isValidSearch)
  const [isRedirectFromLanding, setIsRedirectFromLanding] = useState(!!query.get('address'))

  const [mapCircle, setMapCircle] = useState<google.maps.Circle | undefined>();//useState<google.maps.Circle | undefined>();

  const [isSearchedRecord, SetIsSearchedRecord] = useState(initialState.isSearchedRecord);

  const [massDateToday, SetMassDateToday] = useState(initialState.massDateToday);
  const [massDateTomorrow, SetMassDateTomorrow] = useState(initialState.massDateTomorrow);

  const onResetAllFilters = () => {
    setMassDaysSelected(initialState.massDaysSelected)
    setConfessionDaysSelected(initialState.confessionDaysSelected)
    setDateRadio(initialState.dateRadio)
    setIsDateRadioActive(initialState.isDateRadioActive)
    setIsConfessionDaysActive(initialState.isConfessionDaysActive)
    setMassStartTime(initialState.massStartTime)
    setMassEndTime(initialState.massEndTime)
    setConfessionStartTime(initialState.confessionStartTime)
    setConfessionEndTime(initialState.confessionEndTime)
    setFindByLocation(initialState.findByLocation)
    setAddress(initialState.address)
    setByAddressLocation(initialState.byAddressLocation)
   // setRange(initialState.range)
    setChildrensLiturgy(initialState.childrensLiturgy)
    setYaLiturgy(initialState.yaLiturgy)
    setMarriagePrep(initialState.marriagePrep)
    setRcia(initialState.rcia)
    // setMapCircle(initialState.mapCircle);
    setMapBoundsParishes(initialState.boundsParishes);
    setDispParishesListAll(initialState.dispParishesListAll);
    SetIsSearchedRecord(false);
    SetMassDateToday(initialState.massDateToday);
    SetMassDateTomorrow(initialState.massDateTomorrow);
  }

  const isInitialRender = useIsInitialRender()

  const checkIfValidSearch = () => {
    if ((!massStartTime && massEndTime) || (massStartTime && !massEndTime)) {
      return setIsValidSearch(false)
    }

    if (
      (!confessionStartTime && confessionEndTime) ||
      (confessionStartTime && !confessionEndTime)
    ) {
      return setIsValidSearch(false)
    }

    if (massStartTime && massEndTime && massStartTime.diff(massEndTime) >= 0) {
      return setIsValidSearch(false)
    }

    if (
      confessionStartTime &&
      confessionEndTime &&
      confessionStartTime.diff(confessionEndTime) >= 0
    ) {
      return setIsValidSearch(false)
    }
    return setIsValidSearch(true)
  }

  const checkIfDirty = () => {
    if (massDaysSelected.length !== initialState.massDaysSelected.length)
      return true
    if (confessionDaysSelected.length !== initialState.confessionDaysSelected.length)
      return true
    if (dateRadio !== initialState.dateRadio) return true
    if (isDateRadioActive !== initialState.isDateRadioActive) return true
    if (isConfessionDaysActive !== initialState.isConfessionDaysActive)
      return true
    if (massStartTime !== initialState.massStartTime) return true
    if (massEndTime !== initialState.massEndTime) return true
    if (confessionStartTime !== initialState.confessionStartTime) return true
    if (confessionEndTime !== initialState.confessionEndTime) return true
    if (findByLocation !== initialState.findByLocation) return true
    if (address !== initialState.address) return true
    if (range !== initialState.range) return true
    if (childrensLiturgy !== initialState.childrensLiturgy) return true
    if (yaLiturgy !== initialState.yaLiturgy) return true
    if (marriagePrep !== initialState.marriagePrep) return true
    if (rcia !== initialState.rcia) return true
  }

  const getDateRadioDate = () => {
    if (dateRadio === 'Today') {
      return [moment().day()]
    } else if (dateRadio === 'Tomorrow') {
      return [moment().day() + 1]
    } else if(dateRadio === 'Today and Tomorrow') {
      return [ moment().day(), moment().day() + 1 ];
    } else {
      // Represents all the days of the week
      return [0, 1, 2, 3, 4, 5, 6]
    }
  }

  const filterParishes = async () => {
    let filteredParishes = getInboundParishes();

    // If any filters have been altered or we are coming from the main landing
    // page (this causes dirty flag because we are passing in an address)
    if (checkIfDirty() && !isRedirectFromLanding) {
      // setMapZoom(11);
      setBoundsByRadius();

      const daysMass = !isDateRadioActive
        ? formatDaysToString(massDaysSelected)
        : formatDaysToString(getDateRadioDate());

      const daysConfession = !isDateRadioActive
        ? formatDaysToString(confessionDaysSelected)
        : formatDaysToString(getDateRadioDate());

      const parishMatches: Parish[] = [];

      filteredParishes.forEach((parish: Parish) => {
          if (parish.filterables) {
            parish.filterables.mass.some((mass: Time) => {
              if ((confessionStartTime && confessionEndTime) || (massStartTime && massEndTime)) {
                let massTime       = moment(mass.time, 'HH:mm a');
                let confessionTime = moment(mass.time, 'HH:mm a');

                if (
                  ( confessionTime !== undefined &&
                    MassTypes.Confession.includes(mass.type) &&
                    confessionTime.isSameOrAfter(confessionStartTime) &&
                    confessionTime.isSameOrBefore(confessionEndTime) &&
                    daysConfession.includes(mass.day)
                  )
                  ||
                  (
                    massTime !== undefined &&
                    MassTypes.Mass.includes(mass.type) &&
                    massTime.isSameOrAfter(massStartTime) &&
                    massTime.isSameOrBefore(massEndTime) &&
                    daysMass.includes(mass.day)
                  )
                ) {
                  parishMatches.push(parish);
                  return true;
                }
              } else if (
                (
                  MassTypes.Mass.includes(mass.type) &&
                  daysMass.includes(mass.day)
                )
                ||
                (
                  MassTypes.Confession.includes(mass.type) &&
                  daysConfession.includes(mass.day)
                )
              ) {
                parishMatches.push(parish);
                return true;
              }
            })
          }
      });

      filteredParishes = parishMatches;
      filteredParishes = filterByPrograms(filteredParishes)
    }

    setDisplayedParishes(filteredParishes);
    if(filteredParishes.length > 1){
      setParishPage([]);
    }
    else{
      setParishPage(initialParishInstructions);
    }
  }

  const formatDaysToString = (dayNumbers: number[]) => {
    return dayNumbers.map((day: number) => {
      switch (day) {
        case 0:
          return 'Sunday'
        case 1:
          return 'Monday'
        case 2:
          return 'Tuesday'
        case 3:
          return 'Wednesday'
        case 4:
          return 'Thursday'
        case 5:
          return 'Friday'
        case 6:
          return 'Saturday'
        default:
          return ''
      }
    })
  }

  /**
   * Run the filter updates on every interaction with the filters menu
   */
  useEffect(() => {
    filterParishes();
  }, [
    dateRadio,
    massDaysSelected,
    confessionDaysSelected,
    massStartTime,
    massEndTime,
    confessionEndTime,
    confessionStartTime,
    isDateRadioActive,
    mapZoom,
  ])

  const filterByPrograms = (parishes: Parish[]) => {
    const returnedParishes: Parish[] = []
    parishes.map((parish: Parish) => {
      if (parish.filterables) {
        if (!childrensLiturgy && !yaLiturgy && !marriagePrep && !rcia) {
          returnedParishes.push(parish)
        } else if (
          (childrensLiturgy &&
            parish.filterables.extraPrograms.includes(
              ExtraPrograms.childrensLiturgy
            )) ||
          (yaLiturgy &&
            parish.filterables.extraPrograms.includes(
              ExtraPrograms.yaLiturgy
            )) ||
          (marriagePrep &&
            parish.filterables.extraPrograms.includes(
              ExtraPrograms.marriagePrep
            )) ||
          (rcia &&
            parish.filterables.extraPrograms.includes(ExtraPrograms.rcia))
        ) {
          returnedParishes.push(parish)
        }
      } else {
        // filterable data has not been fetched yet
        returnedParishes.push(parish)
      }
    })

    return returnedParishes
  }

  const toggleDayOfTheWeek = (typeOfEvent: ChurchEvents, index: number) => {
    if (typeOfEvent === ChurchEvents.Mass) {
      ! massDaysSelected.includes(index)
        ? setMassDaysSelected(days => [...days, index])
        : setMassDaysSelected(days => days.filter(day => day !== index))
      setIsMassSearchActive(true);
    }
    if (typeOfEvent === ChurchEvents.Confession) {
      ! confessionDaysSelected.includes(index)
        ? setConfessionDaysSelected(days => [...days, index])
        : setConfessionDaysSelected(days => days.filter(day => day !== index))
      setIsConfessionDaysActive(true);
    }
  }

  useEffect(() => {
    massDaysSelected.length || confessionDaysSelected.length
      ? setIsDateRadioActive(false)
      : setIsDateRadioActive(true)
  }, [massDaysSelected, confessionDaysSelected, dateRadio])

  useEffect(() => {
    if (!isInitialRender) {
      checkIfValidSearch()
    }
  }, [
    massStartTime,
    massEndTime,
    confessionStartTime,
    confessionEndTime,
    massDaysSelected,
    confessionDaysSelected,
    checkIfValidSearch,
    isInitialRender,
  ])

  const onRangeChange = (
    value: number | null,
    stringValue: string,
    el: HTMLInputElement
  ) => {
    setRange(value || initialState.range)

    checkIfValidSearch()
  }

  //depending on which mass date (or both) was / were selected, show parishes for that day(s)
  useEffect( () => {
    if(massDateToday || massDateTomorrow){
      if(massDateToday && massDateTomorrow) 
        setDateRadio('Today and Tomorrow');
      else if(massDateToday)
        setDateRadio('Today');
      else if(massDateTomorrow)
        setDateRadio('Tomorrow');
    }
    else setDateRadio(undefined);
  }, [
    massDateToday,
    massDateTomorrow,
  ]);

  const onDateRadioChanged = (event: React.ChangeEvent<HTMLInputElement> | null, day: SetStateAction<string | undefined>) => {
    if(event != null && day != null){
      // setDateRadio(day)
      // setIsDateRadioActive(true)
      // checkIfValidSearch()
      // setIsMassSearchActive(true)
      // setRange(initialState.range);
      // setMapZoom(11);
      
      if(day === 'Today'){
        SetMassDateToday(!massDateToday);
      }
      if(day === 'Tomorrow'){
        SetMassDateTomorrow(!massDateTomorrow);
      }

      setBoundsByRadius();
      filterParishes();
    }
  }

  const onChangeByLocation = (e: any) => {
    if (e.target.attributes.role.value === LocationOptions.byAddress) {
      setFindByLocation(LocationOptions.byAddress)
      setIsByAddressSearch(true)
      const searchInput: HTMLElement | null = document.querySelector(
        'input.location-search-input'
      )

      if (searchInput) searchInput.focus()
    } else {
      setFindByLocation(LocationOptions.currentLocation)
      userLocation &&
      setMapCenter(new google.maps.LatLng(EdmontonCoordinates.lat, EdmontonCoordinates.lng))
      setIsByAddressSearch(false)
    }
    checkIfValidSearch()
  }

  const onMassStartTimeChange = (value: moment.Moment) => {
    setMassStartTime(value)
    setIsMassSearchActive(true)
  }

  const onMassEndTimeChange = (value: moment.Moment) => {
    setMassEndTime(value)
    setIsMassSearchActive(true)
  }

  const fetchParishes = async () => {
    try {
      let parishData = await ParishAPI.getAll()
      setParishes(parishData)
    } catch (error) {
      console.warn(error)
    }
  }

  /**
   * Fetch the lat/long of all parishes
   */
  useEffect(() => {
    fetchParishes()
  }, [])

  useEffect(() => {
    const gettestVar = async () => {
      const resp = await fetch(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/pages?slug=parishes`);
      const json = await resp.json()
      updatetestVar(json);
    }
    gettestVar();
  }, []);


  /**
   * Fetch the filterable details of each parish upon opening the filter dialog
   */
  useEffect(() => {
    const fetchFilterableData = async () => {
      try {
        let parishFilterables = await ParishAPI.filterables()

        parishes.forEach(parish => {
          parish.filterables = parishFilterables[parish.id]
        })

        setParishes(parishes)
      } catch (error) {
        console.warn(error)
      }
    }

    // only fetch on menu opening, and if not already fetched
    if (showFilters && parishes[0] && !parishes[0].filterables) {
      fetchFilterableData()
    }
  }, [parishes, showFilters])

  const getInboundParishes = () => {
    let distanceOrigin: google.maps.LatLng | undefined
    distanceOrigin = userLocation
      ? new google.maps.LatLng(userLocation.lat, userLocation.lng)
      : mapCenter

    // remove parishes that are out of map bounds
    let inboundsParishes: Parish[] = []
    parishes.forEach(parish => {
      if (distanceOrigin && mapBounds && mapBounds.contains(parish.location)) {
        parish.distance = google.maps.geometry.spherical.computeDistanceBetween(
          distanceOrigin,
          new google.maps.LatLng(parish.location.lat, parish.location.lng)
        )
        inboundsParishes.push(parish)
      }
    });

    // sort by distance from center of map
    inboundsParishes.sort((first: Parish, second: Parish) => {
      if (first.distance && second.distance) {
        if (second.distance > first.distance) {
          return -1
        }

        if (second.distance < first.distance) {
          return 1
        }

        return 0
      }
      return -1
    })

    return inboundsParishes
  }

  useEffect(() => {
    filterParishes()
  }, [parishes, mapBounds, mapCenter, userLocation, showFilters])

  // map km radius being set
  useEffect(() => {
    setBoundsByRadius()
  }, [range])

  const onBoundsChanged = (
    bounds: google.maps.LatLngBounds,
    center: google.maps.LatLng
  ) => {
    setMapBounds(bounds)
  }

  const setBoundsByRadius = () => {
    const circle = new google.maps.Circle({
      radius: range * 1000,
      //center: isByAddressSearch ? byAddressLocation : userLocation,
      center: isByAddressSearch ? byAddressLocation : new google.maps.LatLng(EdmontonCoordinates.lat, EdmontonCoordinates.lng),
    })

    setMapCircle(circle);

    const bounds = circle.getBounds()
    const mapDiv = document.querySelector('.map')
    let mapDim
    if (mapDiv && mapDiv && bounds) {
      mapDim = { height: mapDiv.clientHeight, width: mapDiv.clientWidth }
      setMapZoom(getBoundsZoomLevel(bounds, mapDim))
    }
  }

  const onMarkerClicked = (parish: Parish) => {
    setClicked(parish.id)
    openMenu(parish)
  }

  const openMenu = (parish: Parish) => {
    onEntryClicked(parish)
    setShowMenu(true)
    setHasMenuAnimated(true)
  }

  // const openFilters = () => {
  //   setShowFilters(true)
  //   // setHasFiltersAnimated(true)
  // }

  //toggle the filter menu when the filter button is pressed
  const openFilters = React.useCallback( () => {
    setShowFilters(v => !v);
  }, []);

  const closeMenu = () => {
    setShowMenu(false)

    // try{
    //   window.history.pushState('Closed menu', 'Parishes', '/parishes');
    // }
    // catch(error){
    //   console.error(error);
    // }
  }

  const closeFilters = () => {
    setIsRedirectFromLanding(false);
    setShowFilters(false);
  }

  const onEntryClicked = (parish: Parish) => {
    setClicked(parish.id)
  }

  const onEntryHovered = (parish: Parish) => {
    setClicked(initialState.clicked)
    setHovered(parish.id)
  }

  const onEntryHoverExit = () => {
    setHovered(initialState.hovered)
  }

  const onZoomChanged = (zoom: number) => {
    setMapZoom(zoom)
  }

  const onLocationFound = (position: GeolocationPosition) => {
    setUserLocation({
      lat: position.coords.latitude,
      lng: position.coords.longitude,
      address: '',
    })

    let center = new google.maps.LatLng(
      // position.coords.latitude,
      // position.coords.longitude
      EdmontonCoordinates.lat,
      EdmontonCoordinates.lng
    )
    setMapCenter(center)
    
    let circle = new google.maps.Circle({
      radius: range * 1000,
      center: new google.maps.LatLng(EdmontonCoordinates.lat, EdmontonCoordinates.lng),
    })

    setMapCircle(circle);
  }

  const searchResultClicked = async (/*parishId: number, personId?: number*/ searchResult: SearchResult) => {
    SetIsSearchedRecord(true);
    setClicked(searchResult.parish.id);//parishId)
    setCentered(searchResult.parish.id);//parishId)
    setMapZoom(initialState.mapZoom);

    //A search result was clicked; center the map on where the clicked result's latitude / longitude are
    let center = new google.maps.LatLng(
      searchResult.parish.location.lat,
      searchResult.parish.location.lng
    );
      
    setMapZoom(18);
    setMapCenter(center);

    //the below code will be used to update the site's uri as the new prominent "searched person" section in the parish popup menu uses the parishid and personid from the uri to get the searched person and display their contact info
    let url = `/parishes?parishid=${searchResult.parish.id}`;

    if(searchResult.person){
      url += `&personid=${searchResult.person.ID}`;
      setsearchedPerson(searchResult.person.ID);
    }

    try{
      window.history.pushState('New searched person', 'Parishes', url);
      openMenu(searchResult.parish);
    }
    catch(error){
      console.error(error);
    }
  }

  const onAddressSelect = (position: Location) => {
    setByAddressLocation({
      lat: position.lat,
      lng: position.lng,
      address: '',
    })

    setAddress(position.address)
    setIsByAddressSearch(true)

    const addressLocation = new google.maps.LatLng(position.lat, position.lng)
    setMapCenter(addressLocation)
    setMapZoom(initialState.mapZoom)
    setMapCenter(addressLocation)
  }

  /**
   * Used to update the text input within Filters -> PlacesAutocomplete
   *
   * @param e
   */
  const onAddressUpdate = (e: string) => {
    setAddress(e)
  }

  let list: JSX.Element[] = []
  useEffect(() => {
    parishes.forEach((parish: Parish) => {
      if (parish.id === centered) {
        let center = new google.maps.LatLng(
          parish.location.lat,
          parish.location.lng
        )

        setMapCenter(center)
      }
    })
  }, [centered, parishes])

  //each parish (on the map and in this sidebar list) will have a "id" label (similar to the common store locator labels on other sites)
  let labels = 1;





  // useEffect(() => {
  //   if(dispParishesListAll){
  //     displayedParishes.forEach((parish: Parish, index: number) => {
  //       list.push(
  //         <ListEntry
  //           key={index}
  //           parish={parish}
  //           clicked={parish.id === clicked}
  //           parishClicked={searchResultClicked}
  //           hovered={parish.id === hovered}
  //           onEntryClicked={openMenu}
  //           onEntryHovered={onEntryHovered}
  //           onEntryHoverExit={onEntryHoverExit}
  //           label={String(labels++)/*labels[labelIndex++ % labels.length]*/}
  //         />
  //       );
  //     })
  //   }
  //   else{

  //   }
  // }, [dispParishesListAll]);

  // if(dispParishesListAll){
    //display all relevant parishes in the parish list in the sidebar
  displayedParishes.forEach((parish: Parish, index: number) => {
    list.push(
      <ListEntry
        key={index}
        parish={parish}
        clicked={parish.id === clicked}
        parishClicked={searchResultClicked}
        hovered={parish.id === hovered}
        onEntryClicked={openMenu}
        onEntryHovered={onEntryHovered}
        onEntryHoverExit={onEntryHoverExit}
        label={String(labels++)/*labels[labelIndex++ % labels.length]*/}
      />
    );
  });
      //range * 1000 because range is in km and parish.distance is in meters
      /*if(!isSearchedRecord && parish.distance != null && parish.distance <= range * 1000){
        list.push(
          <ListEntry
            key={index}
            parish={parish}
            clicked={parish.id === clicked}
            parishClicked={searchResultClicked}
            hovered={parish.id === hovered}
            onEntryClicked={openMenu}
            onEntryHovered={onEntryHovered}
            onEntryHoverExit={onEntryHoverExit}
            label={String(labels++)/*labels[labelIndex++ % labels.length]*///}
         /* />
        );
      }
      else if(isSearchedRecord){
        list.push(
          <ListEntry
            key={index}
            parish={parish}
            clicked={parish.id === clicked}
            parishClicked={searchResultClicked}
            hovered={parish.id === hovered}
            onEntryClicked={openMenu}
            onEntryHovered={onEntryHovered}
            onEntryHoverExit={onEntryHoverExit}
            label={String(labels++)/*labels[labelIndex++ % labels.length]*///}
        /* />
        );
      }
    })*/
  // }

  const updateBoundsParishList = (boundsParishesList: Parish[]) => {
    if(boundsParishesList.length > 0){

      if(mapBoundsParishes.length > 0){
        setDisplayedParishes(boundsParishesList);
      }

      // list = [];

      // boundsParishesList.forEach((parish, index: number) => {


        
        // list.push(
        //   <ListEntry
        //     key={index}
        //     parish={parish}
        //     clicked={parish.id === clicked}
        //     parishClicked={searchResultClicked}
        //     hovered={parish.id === hovered}
        //     onEntryClicked={openMenu}
        //     onEntryHovered={onEntryHovered}
        //     onEntryHoverExit={onEntryHoverExit}
        //     label={String(labels++)/*labels[labelIndex++ % labels.length]*/}
        //   />
        // );



      // });

      // setDispParishesListAll(false);
      
      // setMapBoundsParishes(boundsParishesList);
    }
  };

  useEffect(() => {
    /*if (!isByAddressSearch && userLocation) {
      setLocationForDirections(userLocation)
    } 
    else*/ if (isByAddressSearch && byAddressLocation) {
      setLocationForDirections(byAddressLocation)
    } else {
      setLocationForDirections({
        lat: EdmontonCoordinates.lat,
        lng: EdmontonCoordinates.lng,
        address: '',
      })
    }
  }, [isByAddressSearch, userLocation, byAddressLocation])


  const fetchParishesPage = async () => {
    try {
      let parishesData = await ParishAPI.getParishesPage();

      setParishPage(parishesData[0].content.rendered);
      setParishInstructions(parishesData[0].content.rendered);
    } catch (error) {
      console.warn(error)
    }
  }

  useEffect(() => {
    fetchParishesPage()
  }, []);

const [testVar, updatetestVar] = useState();
  useEffect(() => {
    const gettestVar = async () => {
      const resp = await fetch(`${process.env.REACT_APP_API_URL}/wp-json/wp/v2/pages?slug=parishes`);
      const json = await resp.json()
      updatetestVar(json);
    }
    gettestVar();
  }, []);

// <div dangerouslySetInnerHTML={ __html: parishPage }></div>

const showInstructions = () => {
  let elem = null;
  if(list.length < 1){
    elem = '<div className="parish-instrutions" dangerouslySetInnerHTML={{__html:`${parishPage}`}}/>';
  }

  return elem;
};

// useEffect(() => {
//   const testList = () => {
//     if(list.length > 0){
//       setParishPage([]);
//     }
//   };

// }, []);
 



  // searchResultClicked(parseInt(parishId));

  return (
    <div className="App reactParish">
      <div className="parishes">
        <div className="list">
          <header className="page-header icon-style-1 d-flex align-items-center">
            <FontAwesomeIcon icon={faChurch} className={'icon-style-1-icon'} />
            <div className="d-flex justify-content-between w-100">
              <h1 className="margin0">Parishes and Mass Times</h1>
              <button className="filter" onClick={openFilters}>
                <FontAwesomeIcon icon={faFilter} />
                Mass Time Finder
              </button>
            </div>
          </header>

          <Search searchResultClicked={searchResultClicked} searchKeywords={props.searchKeywords}>
          </Search>

          <Filters
            isMassTodaySelected={massDateToday}
            isMassTomorrowSelected={massDateTomorrow}
            onAddressSelect={onAddressSelect}
            onAddressUpdate={onAddressUpdate}
            hasAnimated={hasFiltersAnimated}
            show={showFilters}
            close={closeFilters}
            isMassSearchActive={isMassSearchActive}
            massDaysSelected={massDaysSelected}
            toggleDayOfTheWeek={toggleDayOfTheWeek}
            confessionDaysSelected={confessionDaysSelected}
            isDateRadioActive={isDateRadioActive}
            dateRadio={dateRadio}
            isConfessionDaysActive={isConfessionDaysActive}
            massStartTime={massStartTime}
            massEndTime={massEndTime}
            confessionStartTime={confessionStartTime}
            confessionEndTime={confessionEndTime}
            findByLocation={findByLocation}
            address={address}
            range={range}
            childrensLiturgy={childrensLiturgy}
            yaLiturgy={yaLiturgy}
            marriagePrep={marriagePrep}
            rcia={rcia}
            isValidSearch={isValidSearch}
            setMassDaysSelected={setMassDaysSelected}
            setConfessionDaysSelected={setConfessionDaysSelected}
            setDateRadio={setDateRadio}
            setIsDateRadioActive={setIsDateRadioActive}
            setIsConfessionDaysActive={setIsConfessionDaysActive}
            setMassStartTime={setMassStartTime}
            setMassEndTime={setMassEndTime}
            setConfessionStartTime={setConfessionStartTime}
            setConfessionEndTime={setConfessionEndTime}
            setFindByLocation={setFindByLocation}
            setIsMassSearchActive={setIsMassSearchActive}
            setAddress={setAddress}
            setRange={setRange}
            setChildrensLiturgy={setChildrensLiturgy}
            setYaLiturgy={setYaLiturgy}
            setMarriagePrep={setMarriagePrep}
            setRcia={setRcia}
            setIsValidSearch={setIsValidSearch}
            onMassStartTimeChange={onMassStartTimeChange}
            onMassEndTimeChange={onMassEndTimeChange}
            onChangeByLocation={onChangeByLocation}
            onRangeChange={onRangeChange}
            checkIfDirty={checkIfDirty}
            onDateRadioChanged={onDateRadioChanged}
            checkIfValidSearch={checkIfValidSearch}
            onResetAllFilters={onResetAllFilters}
          />

          {/*!showMenu &&
            <div className="parish-instrutions" dangerouslySetInnerHTML={{__html:`${parishPage}`}}/>*/
          }
          
          {locationForDirections && !showFilters && (
            <Menu
              searchedPerson={searchedPerson}
              foundSearchedPerson={false}
              selected={clicked}
              hasMenuAnimated={hasMenuAnimated}
              showMenu={showMenu}
              closeMenu={closeMenu}
              locationForDirections={locationForDirections}
            />
          )}

          {!showMenu &&
            <div className="parish-list-onmap">
              {list}
            </div>
          }
          
        </div>
        <div className="map">
          {mapCenter && (
            <MyMapComponent
              isSearchedRecord={isSearchedRecord}
              range={range}
              updateBoundsParishList={updateBoundsParishList}
              mapCircle={mapCircle}
              hovered={hovered}
              //userLocation={userLocation}
              byAddressLocation={byAddressLocation}
              onBoundsChanged={onBoundsChanged}
              parishes={displayedParishes}
              clicked={clicked}
              containerElement={<div className="mapContainer" />}
              mapElement={<div style={{ height: `100%` }} />}
              isMarkerShown={true}
              onMarkerClicked={onMarkerClicked}
              center={mapCenter}
              zoom={mapZoom}
              onZoomChanged={onZoomChanged}
            />
          )}
        </div>
      </div>
      {(
        <Geolocated onSuccess={onLocationFound} />
      )}
    </div>
  )
}

export default ParishMap
